import {useCallback, useMemo} from "react";
import {useFilterPrefix} from "./Filters/FilterPrefixProvider";
import {useSearchParams} from "react-router-dom";

export const PAGE_SIZE = 15;

export const usePagination = (pageSize: number) => {
    const filterPrefix = useFilterPrefix();
    const [searchParams, setSearchParams] = useSearchParams();

    const page = useMemo(() => {
        const param = `${filterPrefix}.page`;
        if (searchParams.has(param)) {
            return parseInt(searchParams.get(param) as string);
        }
        return 1;
    }, [searchParams, filterPrefix]);

    const setPage = useCallback((page) => {
        const param = `${filterPrefix}.page`;
        searchParams.set(param, page);
        setSearchParams(searchParams);
    }, [searchParams, filterPrefix, setSearchParams]);

    const pageFilters = useMemo<object>(() => pageSize > 0 ? ({
        limit: pageSize, offset: (page - 1) * pageSize
    }) : ({}), [pageSize, page]);

    return {page, setPage, pageFilters};
}