import React, {FC, ReactElement} from 'react';
import {Typography, Image} from "antd";
import {useOrganisation} from "./OrganisationInformationProvider";

const {Title} = Typography;

export const Brand: FC = (): ReactElement => {
    const organisation = useOrganisation();

    return (
        <div className="logo">
            {organisation?.logo_url ?
                <Image src={organisation.logo_url} preview={false} width={200}/> :
                <Title level={4} style={{color: "white"}}>{organisation?.name}</Title>
            }
        </div>
    );
};