import React, {FC, ReactElement, useCallback, useMemo} from 'react';
import {ExportOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu} from 'antd';
import { saveAs } from 'file-saver';
import {MenuInfo} from "rc-menu/lib/interface";
import api from "../../config/axios";
import {createSearchParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import {buildSearchParams} from "../../config/queryClient";
import {useFilterPrefix} from "./Filters/FilterPrefixProvider";
import {QueryFilter} from "./Filters/types";

interface Props {
    url: string,
    filenamePrefix?: string,
    disabled?: boolean,
    searchParamFilters?: (string | QueryFilter)[],
    defaultFilters?: {[key: string]: (string | number | string[] | number[] | undefined)}
}

export const ExportButton: FC<Props> = ({url, searchParamFilters = [], defaultFilters = {}, filenamePrefix = 'export', disabled = false}): ReactElement => {
    const filterPrefix = useFilterPrefix();
    const [searchParams] = useSearchParams();
    const filters = useMemo(() => ([filterPrefix, ...searchParamFilters]), [searchParamFilters, filterPrefix]);

    const handleSelect = useCallback(async (item: MenuInfo) => {
        let params = buildSearchParams(searchParams, filters);
        params = {...params, ...defaultFilters};
        params = createSearchParams(params);
        let baseUrl = url;
        if (url.endsWith('/')) {
            baseUrl = url.slice(0, -1)
        }
        const response = await api.get(
            `${baseUrl}.${item.key}?${params.toString()}`,
            {responseType: 'blob'}
        );
        const filename = `${filenamePrefix}-${moment().format('MM-DD-YYYY')}.${item.key}`;
        saveAs(response.data, filename);
    }, [url, searchParams, filters, defaultFilters, filenamePrefix]);

    return (
        <Dropdown
            overlay={<Menu onClick={handleSelect}>
                <Menu.Item key="csv">CSV</Menu.Item>
                <Menu.Item key="xlsx">XLSX</Menu.Item>
            </Menu>}
            placement='bottomCenter'
            arrow
            disabled={disabled}
        >
            <Button disabled={disabled} icon={<ExportOutlined/>}>Export</Button>
        </Dropdown>
    );
};