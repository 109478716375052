import React, { FC, ReactElement } from 'react';
import {columns} from "./MissedOrdersDataTableCard";
import {useParams} from "react-router-dom";
import {StudentExerciseAttemptReportDataTableCard} from "../Generic/StudentExerciseAttemptReportDataTableCard";
import {useLabels} from "../../../../hooks/Labels";

export const UnnecessaryOrdersDataTableCard: FC<any> = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();
    return (
        <StudentExerciseAttemptReportDataTableCard
            title={t('unnecessary_orders_title')}
            columns={attemptId ? columns.filter(column => column.key !== 'frequency') : columns}
            query='/reports/investigation/unnecessary-order-items/'
        />
    )
};