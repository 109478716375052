import React, {FC, ReactElement, useEffect} from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import * as Sentry from "@sentry/react";

export const NotFoundPage: FC = (): ReactElement => {
    const navigate = useNavigate();

    useEffect(() => {
        Sentry.captureMessage('404 page');
    }, []);

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
        />
    );
};