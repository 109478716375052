import React, {FC, ReactElement, useCallback, useMemo} from 'react';
import {Card, Radio, Space, Typography} from "antd";
import {useSearchParams} from "react-router-dom";
import {EnrolledCoursesDataTable, EnrolledCoursesDataTableFiltering} from "./EnrolledCoursesDataTable";
import {NotEnrolledCoursesDataTable} from "./NotEnrolledCoursesDataTable";
import {FilterPrefixProvider} from "../../../components/DataTable/Filters/FilterPrefixProvider";
import {useLabels} from "../../../hooks/Labels";

const {Text} = Typography;

enum Tab {
    ENROLLED = 'enrolled',
    NOT_ENROLLED = 'not-enrolled'
}

const tabs = [
    {label: 'Enrolled', value: Tab.ENROLLED},
    {label: 'Not Enrolled', value: Tab.NOT_ENROLLED},
];

const SEARCH_PARAM_KEY = 'tab_courses';

interface TabSelectProps {
    tab: Tab,
    setTab: (tab: Tab) => void
}

const TabSelect: FC<TabSelectProps> = ({tab, setTab}): ReactElement => {
    const {t} = useLabels();
    return (
        <Space size='middle' wrap>
            <Text>{t('course_pl')}</Text>
            <Radio.Group
                options={tabs}
                onChange={(event) => setTab(event.target.value)}
                value={tab}
                optionType='button'
                buttonStyle='solid'
            />
        </Space>
    )
}

export const CoursesDataTableCard: FC = (): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = useMemo<Tab>(() => {
        if (searchParams.has(SEARCH_PARAM_KEY)) return searchParams.get(SEARCH_PARAM_KEY) as Tab;
        return Tab.ENROLLED;
    }, [searchParams]);

    const setTab = useCallback((tab) => {
        searchParams.set(SEARCH_PARAM_KEY, tab);
        setSearchParams(searchParams);
    }, [setSearchParams, searchParams]);

    return (
        <FilterPrefixProvider prefix={`${tab}-courses`}>
            <Card
                title={<TabSelect tab={tab} setTab={setTab}/>}
                className='data-table-card card-header-options'
                extra={tab === Tab.ENROLLED ? <EnrolledCoursesDataTableFiltering/> : undefined}
            >
                {tab === Tab.ENROLLED ? <EnrolledCoursesDataTable/> : <NotEnrolledCoursesDataTable/>}
            </Card>
        </FilterPrefixProvider>
    );
};