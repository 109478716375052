import { FC, ReactElement, useMemo } from 'react';
import {Card, Space, Spin, Tooltip, Typography} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, InfoCircleOutlined} from "@ant-design/icons";
import { Link } from 'react-router-dom';

const {Text} = Typography;

const {Meta} = Card;

export interface IconCardProps {
    title: string | ReactElement,
    value?: null | string | number | ReactElement,
    icon?: ReactElement,
    description?: string | ReactElement,
    isLoading?: boolean,
    tooltip?: string,
    emptyValue?: string,
    unit?: string,
    href?: string
}

const CardContents: FC<IconCardProps> = ({title, value, icon, description, isLoading = false, tooltip, emptyValue = 'N/A', unit = '', href}): ReactElement => {
    return (
        <Card title={
            <Space>
                <Text style={{whiteSpace: 'normal'}}>{title}</Text>
                {tooltip && <Tooltip title={tooltip}><InfoCircleOutlined/></Tooltip>}
            </Space>
        }>
            {isLoading ? <Spin/> : <Meta
                title={value !== null && value !== undefined ? value + unit : emptyValue}
                avatar={icon}
                description={description}
            />}
        </Card>
    )
}

export const IconCard: FC<IconCardProps> = ({title, value, icon, description, isLoading = false, tooltip, emptyValue = 'N/A', unit = '', href}): ReactElement => {
    const cardProps = { title, value, icon, description, isLoading, tooltip, emptyValue, unit };
    
    return href ? <Link style={{ width: '100%' }} to={href}>{<CardContents {...cardProps} />}</Link> : <CardContents {...cardProps} />;
}

interface StatisticProps {
    type: 'positive' | 'negative',
    invertArrow?: boolean
    text?: string,
    value: string | number | undefined,
    unit?: string
}



export const Statistic: FC<StatisticProps> = ({type, text, value, unit, invertArrow= false}): ReactElement => {
    return (
        <Space size='small'>
            <Text strong type={type === 'positive' ? 'success' : 'danger'}>{value}{unit}</Text>
            {type === 'positive' ?
                invertArrow ?
                    <ArrowDownOutlined style={{color: '#21D59B'}}/> :
                    <ArrowUpOutlined style={{color:'#21D59B'}}/> :
                invertArrow ?
                    <ArrowUpOutlined style={{color: '#F0142F'}}/> :
                    <ArrowDownOutlined style={{color: '#F0142F'}}/>
            }
            <Text style={{fontSize: 12}}>{text}</Text>
        </Space>
    )
}