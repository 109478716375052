import Keycloak from "keycloak-js";
import {setUser} from "@sentry/react";
import * as Sentry from '@sentry/browser';
import appConfig from "./config"

const keycloak = new Keycloak({
    url: appConfig.AUTH_ENDPOINT,
    realm: appConfig.AUTH_REALM!,
    clientId: appConfig.AUTH_CLIENT_ID!,
});

export default keycloak;

export const eventHandler = (event: string, error: unknown) => {
    switch (event) {
        case 'onAuthError':
        case 'onAuthRefreshError':
            window.location.reload();
            break;
        case 'onTokenExpired':
            keycloak.updateToken(5)
                .catch(() => keycloak.logout())
                .catch(() => window.location.reload());
            break;
        case 'onAuthSuccess':
            keycloak.loadUserProfile()
                .then((profile) => setUser(profile))
                .catch((error) => Sentry.captureException(error));
            break;
        default:
            break;
    }
};