import moment from "moment";

export const formatMinutesDuration = (dataMinutes: number) => {
    const duration = moment.duration({minute: dataMinutes});
    
    const hours = duration.hours() > 0 ? `${duration.hours()}h` : '';
    const minutes = duration.minutes() > 0 ? `${duration.minutes()}m` : '';
    const seconds = duration.seconds() > 0 ? `${duration.seconds()}s` : '';

    if (duration.days() > 0) {
        return `${duration.days()}d ${hours}`
    }
    else if (duration.hours() > 0) {
        return `${duration.hours()}h ${minutes}`
    }
    return `${duration.minutes()}m ${seconds}`
}