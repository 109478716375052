import React, {FC, ReactElement, useMemo} from 'react';
import {DateFilter, DateFilterUnit} from "./DateFilter";
import {LineChartCard} from "./EngagementLineChart";
import {useLabels} from "../../../hooks/Labels";
import {useFilterPrefix} from "../../../components/DataTable/Filters/FilterPrefixProvider";
import moment from "moment";
import {Datum, LineConfig} from "@ant-design/charts";
import {useSearchParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../config/queryClient";
import {QueryFilter} from "../../../components/DataTable/Filters/types";

interface Props {
    title?: React.ReactNode;
    defaultFilters?: { [key: string]: (string | number | string[] | number []) }
}

export const unitFormatShort: { [key in DateFilterUnit]: string } = {
    [DateFilterUnit.MONTH]: 'MMM YY',
    [DateFilterUnit.DAY]: 'D',
    [DateFilterUnit.HOUR]: 'H:m',
    [DateFilterUnit.YEAR]: 'YYYY',
    [DateFilterUnit.WEEK]: '[Week] #w, gg',
}

export const unitFormatLong: { [key in DateFilterUnit]: string } = {
    [DateFilterUnit.MONTH]: 'MMMM YYYY',
    [DateFilterUnit.DAY]: 'Do MMM YYYY',
    [DateFilterUnit.HOUR]: 'H:m Do MMM',
    [DateFilterUnit.YEAR]: '[Year] YYYY',
    [DateFilterUnit.WEEK]: '[Week] #w, gggg',
}

const chartPropsFactory = (unit: DateFilterUnit, t: (label: string, capitalizeFirstLetter:boolean) => string): Omit<LineConfig, 'data'> => {
    return {
        xField:'start_time',
        xAxis:{
            tickMethod: 'time-cat',
            label: {
                formatter: (text: string) =>
                    moment(text).format(unitFormatShort[unit])
            }
        },
        yField:'total',
        padding:'auto',
        tooltip:{
            title: (text: string) => moment(text).format(unitFormatLong[unit]),
            formatter: (datum: Datum) => ({name: `Completed ${t('activity_pl', false)}`, value: datum.total}),
        }
    }
}

export const useEngagementLineChartProps = (defaultFilters: Props['defaultFilters']) => {
    const {t} = useLabels();
    const filterPrefix = useFilterPrefix();
    const [searchParams] = useSearchParams();

    const unit = useMemo<DateFilterUnit>(() => searchParams.get(`${filterPrefix}.unit`) as DateFilterUnit, [searchParams, filterPrefix]);

    const {data = [], isLoading} = useQuerySearchParams<any[]>(
        '/reports/student-engagement/', [filterPrefix, QueryFilter.COHORTS, QueryFilter.COURSES], {
            enabled: !!unit,
            defaultFilters
        });

    const chartProps = useMemo(() => chartPropsFactory(unit, t), [unit, t]);
    const chartData = useMemo(() => data.map(row => ({...row, ...row['time_period']})), [data]);
    return {chartData, chartProps, isLoading}
}

export const StudentEngagementLineChartCard: FC<Props> = ({title, defaultFilters}): ReactElement => {
    const {t} = useLabels();
    const {isLoading, chartProps, chartData} = useEngagementLineChartProps(defaultFilters);

    return (
        <LineChartCard
            title={title || `${t('student')} engagement`}
            extra={<DateFilter property='created_at'/>}
            isLoading={isLoading}
            data={chartData}
            {...chartProps}
        />
    );
};