import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row} from "antd";
import {DashboardProduct} from "../../../types";
import {AttributeValuesDistributionPieChartCard} from "./AttributeValuesDistributionPieChartCard";
import {AttributePerformanceColumnChartCard} from "./AttributePerformanceColumnChartCard";

interface Props {
    product?: DashboardProduct
}

export const ActivityAttributeReportRow: FC<Props> = ({product}): ReactElement => {
    return (
        <Row gutter={GUTTER}>
            <Col xl={12} xs={24}>
                <AttributeValuesDistributionPieChartCard attributeMeta={product?.primary_attribute_meta}/>
            </Col>
            <Col xl={12} xs={24}>
                <AttributePerformanceColumnChartCard attributeMeta={product?.primary_attribute_meta}/>
            </Col>
        </Row>
    );
};