import React, {FC, ReactElement} from 'react';
import {ActivityViewProps} from "./Exercise";
import {Col, Row} from "antd";
import {StudentsCompletedActivityCard} from "../components/StudentsCompletedActivityCard";
import {GUTTER} from "../../../constants";
// import {createSearchParams, useParams} from "react-router-dom";
// import {useQuery} from "react-query";
// import {StudentActivity} from "../../../types";
// import {DurationHistogramCard} from "../components/DurationHistogramCard";

// TODO: Uncomment duration distribution once duration can be tracked
export const Reading: FC<ActivityViewProps> = ({activity}): ReactElement => {
    // const {activityId} = useParams();
    //
    // const params = useMemo(() => createSearchParams({
    //     activity: activityId?.toString() || '',
    //     fields: ['time_to_complete_minutes']
    // }), [activityId]);
    //
    // const {
    //     data,
    //     isLoading
    // } = useQuery<StudentActivity[]>(`/reports/student-activities/?${params}`)

    return (
        <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
            {/*<Col xs={24} xl={12}>*/}
            {/*    <DurationHistogramCard*/}
            {/*        data={data?.filter(item => item !== null).map(studentActivity => studentActivity.time_to_complete_minutes) || []}*/}
            {/*        average={activity?.avg_completion_time_minutes}*/}
            {/*        isLoading={isLoading}*/}
            {/*    />*/}
            {/*</Col>*/}
            <Col xs={24} xl={24}>
                <StudentsCompletedActivityCard/>
            </Col>
        </Row>
    );
};