import React, {FC, Fragment, ReactElement} from 'react';
import {Card, Col, Space} from "antd";
import {convertFromRaw, Editor, EditorState} from 'draft-js';
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../config/queryClient";
import {StudentActivity, StudentWritingAssignmentActivity} from "../../../types";
import {formatMinutesDuration} from "../../../helpers";
import {ClockCircleOutlined} from "@ant-design/icons";
import {IconCard} from "../../../components/IconCard";
import moment from "moment";
import {DATETIME_FORMAT} from "../../../constants";
import {SpinContainer} from "../../../components/SpinContainer";

export const WritingAssignment: FC = (): ReactElement => {
    const {studentActivityId} = useParams();
    const {data: studentActivity, isLoading: studentActivityLoading} = useQuerySearchParams<StudentActivity>(
        `/reports/student-activities/${studentActivityId}/`, []
    );
    const {
        data: writingAssignment,
        isLoading: writingAssignmentLoading
    } = useQuerySearchParams<StudentWritingAssignmentActivity>(
        `/reports/student-writing-assignment-activities/${studentActivityId}/`, [], {
            enabled: !!studentActivity?.completed
        }
    );

    if (studentActivityLoading || writingAssignmentLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <Col xs={24} xl={6}>
                <Space direction='vertical' size='middle' style={{width: '100%'}}>
                    <IconCard
                        title='Submitted at'
                        value={studentActivity?.completed_datetime ?
                            moment(studentActivity.completed_datetime).format(DATETIME_FORMAT) : 'Not submitted'
                        }
                        icon={<ClockCircleOutlined/>}
                    />
                    <IconCard
                        title='Time Spent'
                        value={studentActivity?.time_to_complete_minutes ?
                            formatMinutesDuration(studentActivity.time_to_complete_minutes) : 'N/A'
                        }
                        icon={<ClockCircleOutlined/>}
                    />
                </Space>
            </Col>
            <Col xs={24} xl={18} style={{display: 'block'}}>
                {!!studentActivity?.completed && <Card title='Submission'>
                    <Editor
                        editorState={EditorState.createWithContent(
                            convertFromRaw(writingAssignment?.content))}
                        readOnly={true}
                        onChange={() => {}}
                    />
                </Card>}
            </Col>
        </Fragment>
    );
};