import React, {ReactElement} from 'react';
import {Form, InputNumber, Space} from "antd";
import {FilterFC} from "./types";
import {OptionSelect} from "./OptionSelect";

interface Props {
    filters?: ('lt' | 'gt' | 'exact' | 'range')[],
    unit?: string,
}

const optionLabelMap = (value: string) => {
    switch (value) {
        case 'lt':
            return 'Less than';
        case 'gt':
            return 'Greater than';
        case 'exact':
            return 'Exact';
        case 'range':
            return 'Range';
        default:
            return '';
    }
}

const singleFieldFilters = ['lt', 'gt', 'exact'];

export const NumericFilter: FilterFC<Props> = ({attribute, title, config, form}): ReactElement => {
    return (
        <Space direction='vertical'>
            {config?.filters?.length &&
                <OptionSelect name={`${attribute}_option`} filters={config.filters} labelMap={optionLabelMap}/>
            }
            <Form.Item noStyle dependencies={[`${attribute}_option`]}>
                {({getFieldValue}) =>
                    singleFieldFilters.includes(getFieldValue(`${attribute}_option`)) ?
                        <Form.Item name={`${attribute}__${getFieldValue(`${attribute}_option`)}`}>
                            <InputNumber placeholder={config.unit}/>
                        </Form.Item> :
                        <Space>
                            <Form.Item name={`${attribute}__range__min`}>
                                <InputNumber placeholder={`Min ${config.unit || ''}`}/>
                            </Form.Item>
                            <Form.Item name={`${attribute}__range__max`}>
                                <InputNumber placeholder={`Max ${config.unit || ''}`}/>
                            </Form.Item>
                        </Space>}
            </Form.Item>
        </Space>
    );
};

NumericFilter.formatter = (value) => value;