import axios from 'axios';
import keycloak from "./keycloak";
import appConfig from "./config"

const api = axios.create({
    baseURL: appConfig.API_BASE_URL,
    timeout: appConfig.API_TIMEOUT,
    headers: {'Content-Type': 'application/json'},
});

/*
    The authorization header is added only if the request is to our api to prevent sending credential to third parties.
    This is run on each request so if the token changes (for example as a result of a token refresh) then the next
    request picks up the new token. Also a check for existing authorization value in the request is done to allow
    overriding of the header from the call site if that happened to be necessary for whatever reason.

    Reference: https://github.com/axios/axios/issues/1383#issuecomment-377870814
*/
api.interceptors.request.use(
    config => {
        if (config.baseURL === appConfig.API_BASE_URL && !config.headers?.Authorization) {
            if (keycloak.authenticated) {
                // @ts-ignore
               config.headers.Authorization = `Bearer ${keycloak.token}`;
            }
        }
        return config;
    },
    error => Promise.reject(error)
);

// Intercepts api responses that have failed and creates an instance of Error according to the status code.
// api.interceptors.response.use(undefined,
//     error => {
//         // Transform Error if needed
//         return Promise.reject(error);
//     }
// );

export default api
