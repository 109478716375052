import React, {FC, ReactElement, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";
import {DataTableCard} from "../../../components/DataTable/DataTableCard";
import {Space, Typography} from "antd";
import {SearchField} from "../../../components/DataTable/SearchField";
import {ExportButton} from "../../../components/DataTable/ExportButton";
import {FilterButton} from "../../../components/DataTable/FilterButton";
import {ColumnsType} from "antd/lib/table";
import {StudentActivity} from "../../../types";
import moment from "moment";
import {Filter, QueryFilter} from "../../../components/DataTable/Filters/types";
import {DATETIME_FORMAT} from "../../../constants";
import {DateFilter} from "../../../components/DataTable/Filters/DateFilter";
import {useLabels} from "../../../hooks/Labels";

const {Text} = Typography;

const filters: Filter<any>[] = [
    {
        attribute: 'completed_datetime_cached',
        title: 'Date Completed',
        component: DateFilter,
        config: {
            filters: ['range', 'lt', 'gt']
        },
    },
];

const columns: ColumnsType<StudentActivity> = [
    {
        title: 'Name',
        dataIndex: ['student', 'full_name'],
        key: 'student.full_name',
        render: (text, record) => <Link to={`/students/${record.student.id}/activity/${record.id}`}>{text}</Link>
    },
    {
        title: 'Email',
        dataIndex: ['student', 'email'],
        key: 'student.email',
    },
    {
        title: 'Date Completed',
        dataIndex: 'completed_datetime',
        key: 'completed_datetime',
        sorter: true,
        render: (value) => moment(value).format(DATETIME_FORMAT)
    }
]

export const StudentsCompletedActivityCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {activityId} = useParams();

    const defaultFilters = useMemo(() => ({
        activity: activityId || '',
        completed_cached: 'true'
    }), [activityId]);

    return (
        <DataTableCard
            title={<Space size='large'>
                <Text>{t('student_pl')}</Text>
                <SearchField placeholder={`${t('student')} name or email`}/>
            </Space>}
            extra={<Space>
                <ExportButton
                    url='/reports/student-activities/'
                    filenamePrefix={`${t('student', false)}-completed-activities`}
                    defaultFilters={defaultFilters}
                    searchParamFilters={[QueryFilter.COHORTS]}
                />
                <FilterButton filters={filters}/>
            </Space>}
            dataTable={{
                searchParamFilters: [QueryFilter.COHORTS],
                defaultFilters,
                columns,
                query: `/reports/student-activities/`
            }}/>
    );
};