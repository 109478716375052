import React, {FC, ReactElement, useMemo} from 'react';
import {DataTableCard} from "../../../components/DataTable/DataTableCard";
import {Space, Tag, Typography} from "antd";
import {SearchField} from "../../../components/DataTable/SearchField";
import {ExportButton} from "../../../components/DataTable/ExportButton";
import {Filter, QueryFilter} from "../../../components/DataTable/Filters/types";
import {FilterButton} from "../../../components/DataTable/FilterButton";
import {Link, useParams} from "react-router-dom";
import {NumericFilter} from "../../../components/DataTable/Filters/NumericFilter";
import {DateFilter} from "../../../components/DataTable/Filters/DateFilter";
import {ColumnsType} from "antd/lib/table";
import {ActivityType, StudentExercisePerformance} from "../../../types";
import moment from "moment";
import {ActivityTypeNames, DATETIME_FORMAT} from "../../../constants";
import {MultipleSelectQueryFilter} from "../../../components/DataTable/Filters/MultipleSelectQueryFilter";
import {useLabels} from "../../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const {Text} = Typography;

const filters: Filter<any>[] = [
    {
        attribute: 'first_score',
        title: 'Initial Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'best_score',
        title: 'Best Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'last_attempt_datetime',
        title: 'Last Attempted',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt']
        },
    },
    {
        attribute: 'primary_attributes',
        title: 'Primary Attributes',
        component: MultipleSelectQueryFilter,
        config: {
            query: 'management/activity-attributes?is_primary=true',
            labelKey: 'value',
            valueKey: 'id'
        }
    },
];

const columns: ColumnsType<StudentExercisePerformance> = [
    {
        title: 'Name',
        dataIndex: ['activity', 'title'],
        key: 'activity.title',
        sorter: true,
        render: (text, record) => <Link to={`/students/${record.student.id}/activity/${record.id}`}>{text}</Link>
    },
    {
        title: 'Primary Attribute',
        dataIndex: ['activity', 'primary_attribute'],
        key: 'activity.primary_attribute',
        render: (attribute) => attribute && <Tag>{attribute.value}</Tag>
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        render: (text, record) => <Text>
            {
                record.activity.renderers.length > 0 ?
                    record.activity.renderers.map(renderer => renderer.name).join(', ') :
                    ActivityTypeNames[record.activity.type]
            }
        </Text>
    },
    {
        title: 'Score',
        children: [
            {
                title: 'Initial',
                dataIndex: 'first_score',
                key: 'first_score',
                sorter: true,
                render: (value, record) => isNumeric(value) ? <Text strong>{value}%</Text> : 'N/A'
            },
            {
                title: 'Best',
                dataIndex: 'best_score',
                key: 'best_score',
                sorter: true,
                render: (value, record) =>
                    isNumeric(value) ? <Text strong type={record.best_score > record.first_score ? 'success' : undefined}>
                        {value}%
                    </Text> : 'N/A'
            }
        ]
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        sorter: true,
        key: 'attempts',
        render: (value, record) => record.activity.type === ActivityType.EXERCISE ?
            value : record.completed ? 1 : 0
    },
    {
        title: 'Last Attempted',
        dataIndex: 'last_attempt_datetime',
        key: 'last_attempt_datetime',
        sorter: true,
        responsive: ['lg'],
        render: (value) => moment(value).format(DATETIME_FORMAT)
    },
]

export const StudentActivitiesDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {studentCourseId} = useParams();
    const defaultFilters = useMemo(() => ({
        student_course: studentCourseId || ''
    }), [studentCourseId]);

    return (
        <DataTableCard
            title={<Space size='large'>
                <Text>{t('activity_pl')}</Text>
                <SearchField placeholder={`${t('activity')} name`}/>
            </Space>}
            extra={<Space>
                <ExportButton
                    url='/reports/student-exercise-performance'
                    filenamePrefix={`${t('student', false)}-activities`}
                    defaultFilters={{
                        exclude: 'activity.renderers,activity.primary_attribute,activity.type',
                        ...defaultFilters
                    }}
                    searchParamFilters={[QueryFilter.COHORTS]}
                />
                <FilterButton filters={filters}/>
            </Space>}
            dataTable={{
                searchParamFilters: [QueryFilter.COHORTS],
                defaultFilters,
                columns,
                query: `/reports/student-exercise-performance/`
            }}/>
    );
};