import React, {FC, ReactElement} from 'react';
import {DataTableCard} from "../../../components/DataTable/DataTableCard";
import {Link, useParams} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {Space, Typography} from "antd";
import {StudentExercisePerformance} from "../../../types";
import {DATETIME_FORMAT} from "../../../constants";
import {SearchField} from "../../../components/DataTable/SearchField";
import {ExportButton} from "../../../components/DataTable/ExportButton";
import {FilterButton} from "../../../components/DataTable/FilterButton";
import {Filter, QueryFilter} from "../../../components/DataTable/Filters/types";
import {NumericFilter} from "../../../components/DataTable/Filters/NumericFilter";
import moment from "moment";
import {DateFilter} from "../../../components/DataTable/Filters/DateFilter";
import {useLabels} from "../../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const {Text} = Typography;

const filters: Filter<any>[] = [
    {
        attribute: 'first_score',
        title: 'First Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'best_score',
        title: 'Best Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'last_attempt_datetime',
        title: 'Last Attempted',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt']
        },
    },
];

const columns: ColumnsType<StudentExercisePerformance> = [
    {
        title: 'Name',
        dataIndex: ['student', 'full_name'],
        key: 'student.full_name',
        render: (text, record) => <Link to={`/students/${record.student.id}/activity/${record.id}`}>{text}</Link>
    },
    {
        title: 'Email',
        dataIndex: ['student', 'email'],
        key: 'student.email',
    },
    {
        title: 'Score',
        children: [
            {
                title: 'First',
                dataIndex: 'first_score',
                key: 'first_score',
                sorter: true,
                render: (value, record) => isNumeric(value) ? <Text strong>{value}%</Text> : 'N/A'
            },
            {
                title: 'Best',
                dataIndex: 'best_score',
                key: 'best_score',
                sorter: true,
                render: (value, record) =>
                    isNumeric(value) ? <Text strong type={record.best_score > record.first_score ? 'success' : undefined}>
                        {value}%
                    </Text> : 'N/A'
            }
        ]
    },
    {
        title: 'Last Attempted',
        dataIndex: 'last_attempt_datetime',
        key: 'last_attempt_datetime',
        sorter: true,
        responsive: ['lg'],
        render: (value) => moment(value).format(DATETIME_FORMAT)
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        sorter: true,
        key: 'attempts'
    },
]

export const StudentPerformanceDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {activityId} = useParams();
    return (
        <DataTableCard
            title={<Space size='large'>
                <Text>{t('student')} Performance</Text>
                <SearchField placeholder={`${t('student')} name or email`}/>
            </Space>}
            extra={<Space>
                <ExportButton
                    url='/reports/student-exercise-performance/'
                    defaultFilters={{
                        activity: activityId,
                        exclude: 'activity.renderers,activity.primary_attribute,activity.type',
                    }}
                    filenamePrefix={`${t('student', false)}-performance`}
                    searchParamFilters={[QueryFilter.COHORTS]}
                />
                <FilterButton filters={filters}/>
            </Space>}
            dataTable={{
                columns,
                defaultFilters: {activity: activityId || ''},
                searchParamFilters: [QueryFilter.COHORTS],
                query: '/reports/student-exercise-performance/'
            }}/>
    );
};