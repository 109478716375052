import React, { FC, ReactElement } from 'react';
import {Empty} from "antd";
import {GUTTER} from "../../../constants";


export const NoStudentAttemptSelected: FC = (): ReactElement => {
    return (
        <div style={{marginTop: GUTTER[0] * 2}}>
            <Empty description='Please select an attempt'/>
        </div>
    );
};