import {FC, ReactElement} from 'react';
import {QueryFilter} from "../../../components/DataTable/Filters/types";
import {DataTableCard} from "../../../components/DataTable/DataTableCard";
import {ActivityRendererReport, DashboardProduct} from "../../../types";
import {ColumnsType} from "antd/lib/table";
import {Link} from "react-router-dom";
import {useLabels} from "../../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const columns = (product?: DashboardProduct): ColumnsType<ActivityRendererReport> => ([
    {
        title: 'Name',
        dataIndex: 'id',
        key: 'id',
        render: (text, row) => {
            const renderers = product?.activity_renderers || [];
            const renderer = renderers.find(renderer => renderer.id === row.id);
            return <Link to={`/${product?.slug}/${renderer?.slug}`}>{renderer?.name || text}</Link>
        }
    },
    {
        title: 'Avg. Score',
        dataIndex: 'average_score',
        key: 'average_score',
        render: (text) => isNumeric(text) ? `${text}%` : 'N/A'
    }
]);

interface Props {
    product?: DashboardProduct
}

export const ActivityRendererPerformanceDataTableCard: FC<Props> = ({product}): ReactElement => {
    const {t} = useLabels();
    return (
        <DataTableCard
            title={`Avg. ${t('activity')} Performance`}
            dataTable={{
                searchParamFilters: [QueryFilter.COHORTS, QueryFilter.COURSES],
                defaultFilters: {dashboard_product: product?.id || ''},
                columns: columns(product),
                query: '/reports/activity-renderers/',
                pagination: false,
                // scroll: DATATABLE_VERTICAL_SCROLL
            }}/>
    );
};