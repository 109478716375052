import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {DATETIME_FORMAT, GUTTER} from "../../constants";
import {SearchField} from "../../components/DataTable/SearchField";
import {Space} from "antd";
import {ExportButton} from "../../components/DataTable/ExportButton";
import {Filter, QueryFilter} from "../../components/DataTable/Filters/types";
import {FilterButton} from "../../components/DataTable/FilterButton";
import {DataTable} from "../../components/DataTable/DataTable";
import {NumericFilter} from "../../components/DataTable/Filters/NumericFilter";
import {ColumnsType} from "antd/lib/table";
import {Student} from "../../types";
import {Link} from "react-router-dom";
import moment from "moment";
import {DateFilter} from "../../components/DataTable/Filters/DateFilter";
import {useLabels} from "../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const filters: Filter<any>[] = [
    {
        attribute: 'attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'average_score',
        title: 'Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'course_completion',
        title: 'Completion',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'last_active',
        title: 'Last active',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt', 'range']
        },
    },
];

const columns: ColumnsType<Student> = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render: (text, record) => <Link to={record.id.toString()}>{text}</Link>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true
    },
    {
        title: 'Avg. Score',
        dataIndex: 'average_score',
        key: 'average_score',
        render: (value) => isNumeric(value) ? `${value}%` : 'N/A',
        sorter: true
    },
    {
        title: 'Completion',
        dataIndex: 'course_completion',
        key: 'course_completion',
        render: (value) => `${value}%`,
        sorter: true
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        key: 'attempts',
        sorter: true
    },
    {
        title: 'Last active',
        dataIndex: 'last_active',
        key: 'last_active',
        sorter: true,
        render: (value) => value ? moment(value).format(DATETIME_FORMAT) : 'N/A'
    }
];

export const StudentsPage: FC = (): ReactElement => {
    const {t} = useLabels();
    return (
        <Fragment>
            <PageHeader title={t('student_pl')}/>
            <div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: GUTTER[0]}}>
                    <SearchField
                        placeholder={`${t('student')} name or email`}
                        encode={
                            (value) => {
                                // check if string starts with or ends with * or is enclosed in quotes
                                return /^\*|\*$|^'.*'$|^".*"$/.test(value) ? value : `*${value}*`;
                            }
                        }
                        decode={(value) => {
                            // remove * from beginning and end if enclosed in *
                            return /^\*.*\*$/.test(value) ? value.replace(/^\*+|\*+$/g, '') : value;
                        }}
                    />
                    <Space>
                        <ExportButton
                            url='/reports/students/'
                            filenamePrefix={t('student_pl', false)}
                            searchParamFilters={[QueryFilter.COURSES, QueryFilter.COHORTS]}
                        />
                        <FilterButton filters={filters}/>
                    </Space>
                </div>
                <DataTable
                    columns={columns}
                    query='/reports/students/'
                    searchParamFilters={[QueryFilter.COURSES, QueryFilter.COHORTS]}
                />
            </div>
        </Fragment>
    );
};