import React, {FC, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {DatePicker, Dropdown, Menu, Space} from "antd";
import moment from "moment";
import {DownOutlined} from "@ant-design/icons";
import {SelectInfo} from "rc-menu/lib/interface";
import {useFilterPrefix} from "../../../components/DataTable/Filters/FilterPrefixProvider";
import {useSearchParams} from "react-router-dom";

const {RangePicker} = DatePicker;

export enum DateFilterUnit {
    MONTH = 'month',
    DAY = 'day',
    HOUR = 'hour',
    YEAR = 'year',
    WEEK = 'week'
}

interface DateFilterOption {
    label: string,
    range: [moment.Moment, moment.Moment],
    unit: DateFilterUnit
}

const filters: DateFilterOption[] = [
    {
        label: 'Last 7 days',
        range: [
            moment().subtract(7, 'days'),
            moment()
        ],
        unit: DateFilterUnit.DAY
    },
    {
        label: 'Last 4 weeks',
        range: [
            moment().subtract(4, 'weeks'),
            moment()
        ],
        unit: DateFilterUnit.WEEK
    },
    {
        label: 'Last 3 months',
        range: [
            moment().subtract(3, 'months'),
            moment()
        ],
        unit: DateFilterUnit.WEEK
    },
    {
        label: 'Last 12 months',
        range: [
            moment().subtract(12, 'months'),
            moment()
        ],
        unit: DateFilterUnit.MONTH
    },
]

const AFTER = '__range_after';
const BEFORE = '__range_before';

interface Props {
    property: string
}

export const DateFilter: FC<Props> = ({property}): ReactElement => {
    const filterPrefix = useFilterPrefix();
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamProperty = useMemo(() => `${filterPrefix}.${property}`, [filterPrefix, property]);
    const searchParamUnit = useMemo(() => `${filterPrefix}.unit`, [filterPrefix]);
    const isSearchParamsRangeDefined = useMemo(() =>
            searchParams.has(`${searchParamProperty}${AFTER}`) && searchParams.has(`${searchParamProperty}${BEFORE}`),
        [searchParamProperty, searchParams]);

    const buildRangeFromSearchParams = useCallback(() => ([
        moment(searchParams.get(`${searchParamProperty}${AFTER}`)), moment(searchParams.get(`${searchParamProperty}${BEFORE}`))
    ]), [searchParams, searchParamProperty]);

    const saveRangeInSearchParams = useCallback((range) => {
        searchParams.set(`${searchParamProperty}${AFTER}`, range[0].toISOString().split('T')[0]);
        searchParams.set(`${searchParamProperty}${BEFORE}`, range[1].toISOString().split('T')[0]);
        setSearchParams(searchParams, {replace: true});
    }, [searchParams, setSearchParams, searchParamProperty]);

    const saveUnitInSearchParams = useCallback((unit: DateFilterUnit) => {
        searchParams.set(searchParamUnit, unit);
        setSearchParams(searchParams, {replace: true});
    }, [searchParams, setSearchParams, searchParamUnit]);

    // -1 Represents a custom date range
    const [selected, setSelected] = useState<number>(isSearchParamsRangeDefined ? -1 : 0);
    const [range, setRange] = useState<any>(isSearchParamsRangeDefined ?
        buildRangeFromSearchParams() : filters[0].range
    );
    const [unit, setUnit] = useState<DateFilterUnit>(searchParams.has(searchParamUnit) ?
        searchParams.get(searchParamUnit) as DateFilterUnit : filters[0].unit
    );

    const selectMenuItem = useCallback(({key}: SelectInfo) => {
        setSelected(parseInt(key));
        const range = filters[parseInt(key)].range
        setRange(range);
        const unit = filters[parseInt(key)].unit;
        setUnit(unit);
        saveRangeInSearchParams(range);
        saveUnitInSearchParams(unit);
    }, [saveUnitInSearchParams, saveRangeInSearchParams]);
    // Save filter in search params if it has not been defined there yet
    useEffect(() => {
        if (isSearchParamsRangeDefined) {
            return;
        }
        saveRangeInSearchParams(range);
        saveUnitInSearchParams(unit);
    }, [isSearchParamsRangeDefined, saveRangeInSearchParams, range, unit, saveUnitInSearchParams]);

    return (
        <Space>
            <Dropdown overlay={<Menu selectedKeys={[selected.toString()]} selectable onSelect={selectMenuItem}>
                {filters.map((filter, index) =>
                    <Menu.Item key={index}>{filter.label}</Menu.Item>)
                }
                {selected === -1 && <Menu.Item key={-1}>Custom</Menu.Item>}
            </Menu>} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {selected === -1 ? 'Custom' : filters[selected].label} <DownOutlined />
                </a>
            </Dropdown>
            <RangePicker
                allowClear={false}
                value={range}
                onChange={(dates) => {
                    setRange(dates);
                    setUnit(DateFilterUnit.DAY);
                    setSelected(-1);
                    saveUnitInSearchParams(DateFilterUnit.DAY);
                    saveRangeInSearchParams(dates)
                }} />
        </Space>
    );
};