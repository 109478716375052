import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {QueryFilter} from "../components/DataTable/Filters/types";

export const useTopFilterSelectionListener = (filter: QueryFilter.COURSES | QueryFilter.COHORTS, id: string | number | undefined) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // Preselect course or cohort in if it isn't (top filter)
    useEffect(() => {
        if (id && !searchParams.has(filter)) {
            searchParams.set(filter, id?.toString() || '');
            setSearchParams(searchParams, {replace: true});
        }
    }, [searchParams, setSearchParams, id, filter]);

    // Navigate to selected course or cohort (top filter)
    useEffect(() => {
        if (id && searchParams.has(filter)) {
            if (id.toString() !== searchParams.get(filter)) {
                navigate(`/${filter}/` + searchParams.get(filter))
            }
        }
    }, [searchParams, navigate, id, filter]);
}