import React, {FC, ReactElement, Fragment, useMemo} from 'react';
import {useParams} from "react-router-dom";
import {PageHeader} from "../../components/PageHeader";
import {useQuerySearchParams} from "../../config/queryClient";
import {SpinContainer} from "../../components/SpinContainer";
import {Activity, ActivityType, Student, StudentActivity} from "../../types";
import {useActivitySubtitle} from "../Courses/hooks/ActivitySubtitle";
import {ClockCircleOutlined, FileOutlined, UserOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import {GUTTER} from "../../constants";
import {DescriptionCard} from "../Courses/components/DescriptionCard";
import {IconCard} from "../../components/IconCard";
import {formatMinutesDuration} from "../../helpers";
import {PageSubHeader} from "./components/PageSubHeader";
import {Exercise} from "./StudentActivity/Exercise";
import {WritingAssignment} from "./StudentActivity/WritingAssignment";

const renderDetails = (activity: Activity) => {
    switch(activity.type) {
        case ActivityType.EXERCISE:
            return <Exercise/>
        case ActivityType.WRITING_ASSIGNMENT:
            return <WritingAssignment/>
        case ActivityType.VIDEO:
        // TODO: Decide what to render based on the activity type
        // return <Video activity={activity}/>
        // case ActivityType.READING:
        //     return <Reading/>
    }
}

export const StudentActivityPage: FC = (): ReactElement => {
    const {studentActivityId, id} = useParams();
    const {data: studentActivity, isLoading: studentActivityLoading} = useQuerySearchParams<StudentActivity>(
        `/reports/student-activities/${studentActivityId}/`, []
    );
    const {data: activity, isLoading: activityLoading} = useQuerySearchParams<Activity>(
        `/reports/activities/${studentActivity?.activity.id}/`,
        [], {
            enabled: !!studentActivity,
            defaultFilters: {
                student: id || '',
            }
        });
    const {data: student, isLoading: studentLoading} = useQuerySearchParams<Student>(
        `/reports/students/${id}`, []);
    const subtitle = useActivitySubtitle(activity);
    const isExercise = useMemo(() => activity?.type === ActivityType.EXERCISE, [activity]);

    if (studentActivityLoading || activityLoading || studentLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <PageHeader title={activity?.title} icon={FileOutlined} subtitle={subtitle}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={12}>
                    <DescriptionCard html={activity?.description || 'N/A'}/>
                </Col>
                {isExercise && <Col xs={12} xl={6} style={{display: 'block'}}>
                    <IconCard
                        title='Avg. Score'
                        value={activity?.average_score}
                        unit='%'
                        icon={<ClockCircleOutlined/>}
                    />
                </Col>}
                <Col xs={12} xl={6} style={{display: 'block'}}>
                    <IconCard
                        title='Time Est.'
                        value={activity?.estimated_time_minutes ? formatMinutesDuration(activity.estimated_time_minutes) : 'N/A'}
                        icon={<ClockCircleOutlined/>}
                    />
                </Col>
            </Row>
            <PageSubHeader title={student?.full_name} icon={UserOutlined}/>

            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                {activity && renderDetails(activity)}
            </Row>
        </Fragment>
    );
};