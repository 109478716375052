import React, {FC, Fragment, ReactElement, useMemo} from 'react';
import {Card, Empty, Spin, Typography} from "antd";
import {Column, ColumnConfig} from "@ant-design/plots";
import {countBy} from 'lodash';
import {formatMinutesDuration} from "../../../helpers";

const {Title} = Typography;

interface Props {
    data: (number | null)[],
    average?: number | null,
    isLoading?: boolean
}

const BUCKETS: [number, number][] = [
    [0, 5],
    [5, 10],
    [10, 30],
    [30, 60],
    [60, Infinity],
]

const findBucket = (value: number, buckets: number[][]): number => {
    return buckets.findIndex(([low, high], i) => low <= value && value < high);
}

const bucketToString = ([low, high]: [number, number]) => {
    if (high === Infinity) {
        return `${low}+ mins`
    }
    return `${low}-${high} mins`
}

const columnChartProps: Omit<ColumnConfig, 'data'> = {
    xField: 'bucket',
    yField: 'value',
    tooltip: {
        showMarkers: false
    },
    meta: {
      value: {
          alias: 'Attempts'
      }
    },
    interactions: [
        {
            type: 'element-highlight',
        },
    ]
}

export const DurationHistogramCard: FC<Props> = ({data, average, isLoading = false}): ReactElement => {
    const columnChartData = useMemo(() => {

        const bucketedData = countBy(data
            // @ts-ignore
            .map((value: number) => ({bucket: findBucket(Math.abs(value), BUCKETS)}))
            .filter(({bucket}) => bucket !== -1), 'bucket');

        return BUCKETS.map((bucket, i) => (
            {bucket: bucketToString(bucket), value: bucketedData[i.toString()]}
        ));
    }, [data]);

    return (
        <Card title='Duration' className='chart-card'>
            {data && data.length > 0 ? <Fragment>
                <Title level={3}>Avg. {formatMinutesDuration(Math.floor(average || 0))}</Title>
                <Column
                    data={columnChartData}
                    {...columnChartProps}
                />
            </Fragment> : isLoading ? <Spin/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Card>
    );
};