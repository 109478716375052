import React, {FC, ReactElement} from 'react';
import {FileProtectOutlined} from "@ant-design/icons";
import {IconCard} from "../../../IconCard";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {ConversationMissedItems} from "../../../../types";
import {allSearchParamFilters} from "../../../DataTable/Filters/types";
import {useLabels} from "../../../../hooks/Labels";

export const AverageMissedItemsCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {data, isLoading} = useQuerySearchParams<ConversationMissedItems>(
        `reports/conversation/missed-coverage-items-stats`,
        allSearchParamFilters
    )

    return (
        <IconCard
            title={t('average_missed_items_title')}
            isLoading={isLoading}
            value={data?.avg_missed_items}
            icon={<FileProtectOutlined/>}
        />
    );
};