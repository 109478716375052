import {
    AppstoreAddOutlined,
    BarChartOutlined,
    BookOutlined,
    HomeOutlined,
    UserOutlined,
    WechatOutlined
} from "@ant-design/icons";
import {FC} from "react";
import {Navigate, Outlet, RouteObject, useRoutes as useRouterRoutes} from "react-router-dom";
import {CoursesPage} from "../pages/Courses/CoursesPage";
import {CoursePage} from "../pages/Courses/CoursePage";
import {useOrganisation} from "../layout/OrganisationInformationProvider";
import {NotFoundPage} from "../pages/NotFoundPage";
import {SelectProps} from "antd";
import {ActivityPage} from "../pages/Courses/ActivityPage";
import {ProductPage} from "../pages/Products/ProductPage";
import {ExerciseDashboardViews} from "../types";
import {CohortsPage} from "../pages/Cohorts/CohortsPage";
import {CohortPage} from "../pages/Cohorts/CohortPage";
import {StudentsPage} from "../pages/Students/StudentsPage";
import {StudentPage} from "../pages/Students/StudentPage";
import {StudentActivityPage} from "../pages/Students/StudentActivityPage";
import {StudentAttempt} from "../pages/Students/components/StudentAttempt";
import {StudentCoursePage} from "../pages/Students/StudentCoursePage";
import {NoStudentAttemptSelected} from "../pages/Students/components/NoStudentAttemptSelected";
import {OverviewPage} from "../pages/Overview/OverviewPage";
import {capitalize} from "../hooks/Labels";
import {DashboardViewProductTabMapping} from "../components/DashboardViews/ProductTabs";

export interface FilterProps {
    mode?: SelectProps<any>['mode'] | 'single'
}

export interface FilterRouteObject extends RouteObject {
    children?: FilterRouteObject[],
    cohortFilter?: boolean | FilterProps,
    courseFilter?: boolean | FilterProps,
}

interface MenuItem extends FilterRouteObject {
    path: string,
    label: string,
    icon: FC,
}

const routes: (MenuItem | FilterRouteObject)[] = [
    {
        path: '/',
        label: 'overview',
        icon: HomeOutlined,
        element: <OverviewPage/>,
    },
    // All Dashboard Products will be included here by `useRoutesWithProducts`
    {
        path: '/cohorts',
        label: 'cohort_pl',
        icon: UserOutlined,
        courseFilter: true,
        element: <Outlet/>,
        children: [
            {
                index: true,
                element: <CohortsPage/>,
                courseFilter: true
            },
            {
                path: ':id',
                element: <CohortPage/>,
                courseFilter: true,
                cohortFilter: {mode: 'single'}
            },
        ]
    },
    {
        path: '/courses',
        label: 'course_pl',
        icon: BookOutlined,
        cohortFilter: true,
        element: <Outlet/>,
        children: [
            {
                index: true,
                element: <CoursesPage/>,
                cohortFilter: true
            },
            {
                path: ':id',
                element: <CoursePage/>,
                cohortFilter: true,
                courseFilter: {mode: 'single'}
            },
            {
                path: 'activities/:activityId',
                element: <ActivityPage/>,
                cohortFilter: true,
                courseFilter: {mode: 'single'}
            }
        ]
    },
    {
        path: '/students',
        label: 'student_pl',
        icon: UserOutlined,
        courseFilter: true,
        cohortFilter: true,
        element: <Outlet/>,
        children: [
            {
                index: true,
                element: <StudentsPage/>,
                cohortFilter: true,
                courseFilter: true
            },
            {
                path: ':id',
                element: <Outlet/>,
                cohortFilter: true,
                courseFilter: true,
                children: [
                    {
                        index: true,
                        element: <StudentPage/>,
                        cohortFilter: true,
                        courseFilter: true
                    },
                    {
                        path: 'activity/:studentActivityId',
                        element: <StudentActivityPage/>,
                        children: [
                            {
                                index: true,
                                element: <NoStudentAttemptSelected/>,
                                cohortFilter: true,
                                courseFilter: true
                            },
                            {
                                path: ':attemptId',
                                element: <StudentAttempt/>
                            }
                        ]
                    },
                    {
                        path: 'course/:studentCourseId',
                        element: <StudentCoursePage/>
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <NotFoundPage/>,
    }
];

export const isMenuItem = (item: MenuItem | RouteObject): item is MenuItem => 'label' in item && 'icon' in item;


export const useRoutesWithProducts = (): (MenuItem | FilterRouteObject)[] => {
    const {products, labels} = useOrganisation();
    const routesWithLabels = routes.map(route => {
        const menuItemRoute = route as MenuItem;
        if(menuItemRoute.label) {
            menuItemRoute.label = capitalize(labels[menuItemRoute.label] ?? menuItemRoute.label);
            return menuItemRoute;
        }
        return route;
    })
    return [
        routesWithLabels[0],
        // Insert products in routes
        ...(products?.map((product): MenuItem => {
            const tabs = product.activity_renderers.filter(renderer =>
                ExerciseDashboardViews.includes(renderer.dashboard_view)
            );
            return {
                cohortFilter: true,
                courseFilter: true,
                path: `/${product.slug}`,
                label: product.name,
                element: <ProductPage/>,
                icon: productIconMap(product.id),
                // Add activity renderers as children routes
                children: tabs.length > 0 ?
                    [
                        {index: true, element: <Navigate to={tabs[0].slug}/>},
                        ...tabs.map(renderer => ({
                            path: renderer.slug,
                            label: renderer.name,
                            element: DashboardViewProductTabMapping[renderer.dashboard_view],
                            cohortFilter: true,
                            courseFilter: true
                        }))
                    ] : []
            }
        }) || []),
        ...routesWithLabels.slice(1)
    ]
}

export const useRoutes = () => {
    const routes = useRoutesWithProducts();
    return useRouterRoutes(routes);
}

const productIconMap = (productId: number): FC => {
    switch(productId) {
        case 1:
            return BarChartOutlined;
        case 2:
            return WechatOutlined;
        default:
            return AppstoreAddOutlined;
    }
}