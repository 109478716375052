import React, { FC } from 'react';
import { useContext, createContext } from 'react';
import {useQuery} from "react-query";
import {SpinContainer} from "../components/SpinContainer";
import {DashboardProduct} from "../types";

interface Organisation {
    id: number,
    name: string,
    logo_url?: string,
    products?: DashboardProduct[],
    labels: {[key:string]: string}
}

export const OrganisationInformationContext = createContext<Organisation | undefined>(undefined);

export const OrganisationInformationProvider: FC = ({children}) => {
    const {data, isLoading} = useQuery<Organisation>('/organisation', {
        staleTime: Infinity,
    });

    return (
        isLoading
            ? <SpinContainer screenSize/>
            : <OrganisationInformationContext.Provider value={data}>
                {children}
            </OrganisationInformationContext.Provider>
    )
}

export const useOrganisation = () => {
    const organisation = useContext(OrganisationInformationContext);
    if(organisation === undefined){
        throw new Error('useOrganisation must be used within a OrganisationInformationProvider');
    }
    return organisation;
}