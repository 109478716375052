import React, {FC, ReactElement, useState} from 'react';
import {Layout as AntLayout, Grid} from 'antd';
import './Layout.css';
import {Sidebar} from "./Sidebar";
import {Header} from "./Header";
import {useRoutes} from "../config/routes";
import {GUTTER} from "../constants";

const { Content } = AntLayout;
const {useBreakpoint} = Grid;

export const Layout: FC = (): ReactElement => {
    const routes = useRoutes();
    const screens = useBreakpoint();
    const [collapsed, setCollapsed] = useState<boolean>(!screens.lg);

    return (
        <AntLayout style={{ minHeight: '100vh' }}>
            <Sidebar collapsed={collapsed} onCollapse={setCollapsed}/>
            <AntLayout className="site-layout" style={{ marginLeft: collapsed ? 0 : 200 }}>
                <Header collapsed={collapsed} onCollapse={setCollapsed}/>
                <Content style={{ margin: `0 ${GUTTER[0]}px`, paddingBottom: `${GUTTER[0]}px` }}>
                    {routes}
                </Content>
            </AntLayout>
        </AntLayout>
    );
};