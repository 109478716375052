import React, { FC, ReactElement } from 'react';
import {Col, Row, Space} from "antd";
import {GUTTER} from "../../../constants";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageMissedItemsCard} from "../components/ConversationStory/AverageMissedItemsCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";

export const PhysicalExaminationTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
            <Col xl={6} xs={24}>
                <Space direction='vertical' size='middle' style={{width: '100%'}}>
                    <AverageScoreCard/>
                    <AverageMissedItemsCard/>
                    <AverageTimeSpentCard/>
                </Space>
            </Col>
        </Row>
    );
};