import React, {FC, ReactElement} from 'react';
import {Breadcrumb, PageHeader as AntPageHeader, Space, Typography} from "antd";
import {GUTTER} from "../constants";

const {Title, Text} = Typography;

export interface SubtitleItemProps {
    icon?: ReactElement,
    title: string
}

interface PageTitleProps {
    title?: string,
    icon?: FC<any>,
    subtitle?: SubtitleItemProps[]
}

const PageTitle: FC<PageTitleProps> = ({title, icon: Icon, subtitle}): ReactElement => (
    <Space direction='vertical'>
        <Space align='center'>
            {Icon && <Icon style={{color: 'black', fontSize: 38}}/>}
            <Title level={1} style={{fontWeight: 400, whiteSpace: 'normal', margin: 0}}
                   ellipsis={{rows: 3}}>{title}</Title>
        </Space>
        {subtitle && <Breadcrumb separator='|' style={{marginLeft: 4}}>
            {subtitle.map(({icon, title}, index) =>
                // Can't create a custom component for that https://github.com/ant-design/ant-design/issues/4853
                <Breadcrumb.Item
                    key={index}
                    onClick={(e) => e.preventDefault()}
                >
                    {icon}
                    <Text style={{fontWeight: 400}} type='secondary'>{title}</Text>
                </Breadcrumb.Item>
            )}</Breadcrumb>}
    </Space>
)

export const PageHeader: FC<PageTitleProps> = ({title, icon, subtitle}): ReactElement => (
    <AntPageHeader
        backIcon={false}
        style={{padding: `${GUTTER[0]}px 0`}}
        title={<PageTitle title={title} subtitle={subtitle} icon={icon}/>}
    />
);
