import React, {FC, ReactElement, useEffect, useMemo} from 'react';
import {Card, Menu, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../config/queryClient";
import {StudentExerciseAttempt} from "../../../types";
import moment from "moment";
import {SHORT_DATETIME_FORMAT} from "../../../constants";
import {renderActivityScore} from "./RecentActivityAttemptsDataTable";

const {Text} = Typography;

export const AttemptsMenuCard: FC = (): ReactElement => {
    const {id, studentActivityId, attemptId} = useParams();
    const navigate = useNavigate();
    const {data = [], isLoading} = useQuerySearchParams<StudentExerciseAttempt[]>('/reports/student-exercise-attempts/', [], {
        defaultFilters: {
            student: id || '',
            student_activity: studentActivityId || '',
            fields: 'id,completed_datetime,score,passed'
        }
    })

    const bestAttempt = useMemo<StudentExerciseAttempt | undefined>(() => data.length > 0 ? data.reduce((prev, curr) =>
        prev.score > curr.score ? prev : curr
    ) : undefined, [data]);

    useEffect(() => {
        if (bestAttempt && !attemptId) {
            navigate(bestAttempt.id.toString(), {replace: true});
        }
    }, [bestAttempt, attemptId, navigate]);

    return (
        <Card title='Attempts' loading={isLoading}>
            <div className='attempts-menu-header'>
                <Text type='secondary'>Date</Text>
                <Text type='secondary'>Score</Text>
            </div>
            <Menu
                inlineIndent={0}
                mode='inline'
                style={{borderRight: 'none'}}
                selectedKeys={attemptId ? [attemptId] : []}
                onClick={({key}) => navigate(`/students/${id}/activity/${studentActivityId}/${key}`)}
            >
                {data
                    .filter(item => item.completed_datetime !== null)
                    .map((item) => (
                        <Menu.Item key={item.id}>
                            <div className='attempts-menu-item'>
                                <Text>{moment(item.completed_datetime).format(SHORT_DATETIME_FORMAT)}</Text>
                                <Text strong={item.id === bestAttempt?.id}>
                                    {renderActivityScore(item.score, item)}
                                </Text>
                            </div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        </Card>
    );
};