import React, { FC, ReactElement } from 'react';
import {ColumnsType} from "antd/lib/table";
import {HistoryItem} from "../../../../types";
import {Tag} from "antd";
import {useParams} from "react-router-dom";
import {useLabels} from "../../../../hooks/Labels";
import {StudentExerciseAttemptReportDataTableCard} from "../Generic/StudentExerciseAttemptReportDataTableCard";

const columns: ColumnsType<HistoryItem> = [
    {
        title: 'Item',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Frequency',
        dataIndex: 'num_occurrences',
        key: 'num_occurrences',
        render: (text) => `${text} times`
    },
    {
        title: 'Tag',
        dataIndex: 'tag',
        key: 'tag',
        render: (tag) => tag && <Tag color={tag.includes('critical') ? 'red' : 'orange'}>{tag}</Tag>
    },
]

export const CommonlyMissedDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();
    return (
        <StudentExerciseAttemptReportDataTableCard
            title={t('commonly_missed_title')}
            columns={attemptId ? columns.filter(column => column.key !== 'num_occurrences') : columns}
            query='/reports/conversation/missed-coverage-items/'
        />
    )
};