import React, { FC, ReactElement } from 'react';
import {Card} from "antd";
import {DataTable, DataTableProps} from "./DataTable";
import {Model} from "../../types";
import './DataTableCard.css';

export interface DataTableCardProps<T extends Model> {
    title: string | ReactElement,
    dataTable: DataTableProps<T>,
    extra?: string | ReactElement
}

export const DataTableCard: FC<DataTableCardProps<any>> = ({title, dataTable, extra}): ReactElement => {
    return (
        <Card title={title} className='data-table-card card-header-options' extra={extra}>
            <DataTable {...dataTable}/>
        </Card>
    );
};