import React, {FC, ReactElement} from 'react';
import {Card, Empty} from "antd";
import {Line, LineConfig} from "@ant-design/charts";
import {SpinContainer} from "../../../components/SpinContainer";

interface Props extends LineConfig{
    title: React.ReactNode,
    extra?: React.ReactNode
    isLoading: boolean,
}

const GlitchyLineChartCard: FC<Props> = ({title, extra, data, isLoading,...rest}): ReactElement => {
    // It's glitchy because the graph repaints on every parent state update
    return <Card title={title} className='chart-card' extra={extra}>
        {
            isLoading
            ? <SpinContainer/>
            : data.length > 0
                ? <Line
                    data={data}
                    {...rest}
            /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>

        }
        </Card>
};

export const LineChartCard = React.memo(GlitchyLineChartCard)

