import React, {FC, Fragment, ReactElement, useState} from 'react';
import {Button, Modal} from "antd";

export interface FeedbackModalFC<T> extends FC<T> {
    title: string
}

interface Props {
    title: string
}

// TODO: Throws findDOMNode is deprecated in StrictMode
export const FeedbackModal: FC<Props> = ({title, children}): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Fragment>
            <Button type="link" onClick={() => setOpen(true)}>{title}</Button>
            <Modal visible={open} onCancel={() => setOpen(false)} title={title} footer={null}>
                <div style={{maxHeight: 500, overflow: 'auto'}}>{children}</div>
            </Modal>
        </Fragment>
    );
};