import React, {FC, ReactElement, useMemo} from 'react';
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {Card, Table, Typography} from "antd";
import {StudentExerciseAttempt} from "../../../../types";
import {ColumnsType} from "antd/lib/table";
import {useLabels} from "../../../../hooks/Labels";
import {DATATABLE_VERTICAL_SCROLL, GUTTER} from "../../../../constants";

const {Text, Paragraph} = Typography;

const columns: ColumnsType<any> = [
    {
        title: 'Section',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        render: value => value + '%'
    },
];

export const SectionTextFeedback: FC<any> = ({textFeedback}): ReactElement => (
    <Text>
        {textFeedback}
    </Text>
)

export const AttemptPerformanceDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();

    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(
        `/reports/student-exercise-attempts/${attemptId}/`, [], {
            defaultFilters: {
                fields: 'id,feedback'
            }
        })

    const sections = useMemo(() => {
        return data?.feedback?.stages?.at(0)?.marking_feedback?.sections?.map((section: any) => ({
            title: section.title,
            generatedTextFeedback: section.feedback.generated_feedback,
            score: section.feedback.score,
        })) || [];
    }, [data]);

    return (
        <Card title={t('section_performance_title')} className='data-table-card'>
            {
                !!data && !isLoading
                && !!data.feedback?.stages?.at(0)?.marking_feedback?.feedback?.generated_feedback
                &&
                    <Paragraph style={{padding: `${GUTTER[0]}px`}}>
                        {data.feedback.stages[0].marking_feedback.feedback.generated_feedback}
                    </Paragraph>
            }
            {
                (isLoading || sections.length > 0)
                && <Table
                    loading={isLoading}
                    dataSource={sections}
                    columns={columns}
                    rowKey='title'
                    pagination={false}
                    expandable={{
                        expandedRowRender: record => <SectionTextFeedback textFeedback={record.generatedTextFeedback}/>,
                        rowExpandable: record => record.generatedTextFeedback && record.generatedTextFeedback.length > 0,
                    }}
                    scroll={DATATABLE_VERTICAL_SCROLL}
                />
            }
        </Card>
    );
};