import React, { FC, ReactElement } from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row} from "antd";
import {SectionPerformanceDataTableCard} from "../components/Generic/SectionPerformanceDataTableCard";
import {useParams} from "react-router-dom";
import {
    AttemptPerformanceDataTableCard
} from "../components/TextFeedback/AttemptPerformanceDataTableCard";

export const TranscriptFeedbackRow: FC = (): ReactElement => {
    const {attemptId} = useParams();
    return (
        <Row gutter={GUTTER}>
            <Col>
                {attemptId ? <AttemptPerformanceDataTableCard/> :
                    <SectionPerformanceDataTableCard type='conversation'/>}
            </Col>
        </Row>
    );
};