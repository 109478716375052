import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row, Space} from "antd";
import {CommonlyMissedDataTableCard} from "../components/ConversationStory/CommonlyMissedDataTableCard";
import {
    SectionPerformanceDataTableCard
} from "../components/Generic/SectionPerformanceDataTableCard";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";
import {AverageMissedItemsCard} from "../components/ConversationStory/AverageMissedItemsCard";

export const ConversationStoryTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
                <Col xl={6} xs={24}>
                    <Space direction='vertical' size='middle' style={{width: '100%'}}>
                        <AverageScoreCard/>
                        <AverageMissedItemsCard/>
                        <AverageTimeSpentCard/>
                    </Space>
                </Col>
                <Col xl={10} xs={24}>
                    <CommonlyMissedDataTableCard/>
                </Col>
                <Col xl={8} xs={24}>
                    <SectionPerformanceDataTableCard type='conversation'/>
                </Col>
        </Row>
    );
};