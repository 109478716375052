import React, {FC, ReactElement} from 'react';
import {Card, Empty, Spin} from "antd";
import {Pie, PieConfig} from "@ant-design/charts";

const config = {
    data: [],
    appendPadding: 10,
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }:any) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [
        {
            type: 'element-active',
        },
    ],
};

interface Props extends PieConfig {
    angleField: string,
    colorField: string,
    data: object[],
    title: string,
    isLoading?: boolean
}

export const PieChartCard: FC<Props> = ({title, isLoading,  ...rest}): ReactElement => {

    return (
        <Card title={title} className='chart-card'>
            {isLoading ? <Spin/> :
                rest.data.length > 0 ? <Pie
                {...config}
                {...rest}
                // legend={{
                //     itemValue: {
                //         formatter: (text: string, item: any, index: any) => {
                //             console.log(text, item, index);
                //             // @ts-ignore
                //             return rest.data.find((entry: any) => entry[rest.colorField] === item.value)[rest.angleField];
                //         }
                //     }
                // }}
            /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card>
    );
};