import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {DATE_FORMAT, GUTTER} from "../../constants";
import {Col, Row} from "antd";
import {DescriptionCard} from "../Courses/components/DescriptionCard";
import {BookOutlined, CheckCircleOutlined, ClockCircleOutlined, UserOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../config/queryClient";
import {Course, Student, StudentCourse} from "../../types";
import {SpinContainer} from "../../components/SpinContainer";
import {CourseScoreHistogramCard} from "../Courses/components/CourseScoreHistogramCard";
import {PageSubHeader} from "./components/PageSubHeader";
import {StudentRankingCard} from "./components/StudentRankingCard";
import {IconCard} from "../../components/IconCard";
import moment from "moment";
import {StudentActivitiesDataTableCard} from "./components/StudentActivitiesDataTableCard";

export const StudentCoursePage: FC = (): ReactElement => {
    const {id, studentCourseId} = useParams();

    const {data: student, isLoading: studentLoading} = useQuerySearchParams<Student>(
        `/reports/students/${id}/`,
        []
    );

    const {data: studentCourse, isLoading: studentCourseLoading} = useQuerySearchParams<StudentCourse>(
        `/reports/student-courses/${studentCourseId}/`,
        []
    );
    const {data: course, isLoading: courseLoading} = useQuerySearchParams<Course>(
        `/reports/courses/${studentCourse?.course.id}`, [], {
            enabled: !!studentCourse
        }
    )

    if (studentCourseLoading || courseLoading || studentLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <PageHeader title={studentCourse?.course.title} icon={BookOutlined}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={12}  style={{display: 'block'}}>
                    <DescriptionCard html={course?.description || 'N/A'}/>
                </Col>
                <Col xs={24} xl={12}  style={{display: 'block'}}>
                    <CourseScoreHistogramCard
                        defaultFilters={{courses: course?.id}}
                    />
                </Col>
            </Row>
            <PageSubHeader title={student?.full_name} icon={UserOutlined}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={8}>
                    <StudentRankingCard defaultFilters={{courses: course?.id || ''}}/>
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title={`Score${!studentCourse?.score ? ' (provisional)' : ''}`}
                        value={studentCourse?.score || studentCourse?.provisional_score}
                        unit='%'
                        icon={<CheckCircleOutlined />}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title='Completed'
                        value={studentCourse?.completed_datetime ? moment(studentCourse.completed_datetime).format(DATE_FORMAT) : 'Not Completed'}
                        icon={<ClockCircleOutlined />}
                    />
                </Col>
            </Row>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={24}>
                    <StudentActivitiesDataTableCard/>
                </Col>
            </Row>
        </Fragment>
    );
};