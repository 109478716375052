import React, {FC, ReactElement, useMemo} from 'react';
import {Menu, Layout, Button, Grid} from "antd";
import {isMenuItem, useRoutesWithProducts} from "../config/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {LogoutOutlined} from "@ant-design/icons";
import {useKeycloak} from "@react-keycloak/web";
import {Brand} from "./Brand";
import {GUTTER} from "../constants";

const {Sider} = Layout;
const {useBreakpoint} = Grid;

interface Props {
    collapsed: boolean,
    onCollapse: React.Dispatch<React.SetStateAction<boolean>>
}

export const Sidebar: FC<Props> = ({collapsed, onCollapse}): ReactElement => {
    const location = useLocation();
    const routes = useRoutesWithProducts();
    const {keycloak} = useKeycloak();
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const selectedMenuKey = useMemo(() => `/${location.pathname.split('/')[1]}`, [location]);
    return (
        <Sider
            theme='dark'
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            onCollapse={onCollapse}
            trigger={null}
            collapsible
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className='sidebar-content'>
                <div>
                    <Brand/>
                    <Menu theme='dark' selectedKeys={[selectedMenuKey]} mode="inline">
                        {routes.filter(isMenuItem).map(item =>
                            <Menu.Item
                                key={item.path}
                                icon={item.icon ? <item.icon/> : undefined}
                                onClick={() => {
                                    if (!screens.lg) onCollapse(true);
                                    navigate(item.path, {});
                                }}
                                style={{textDecoration: 'capitalize'}}
                            >
                                {item.label}
                            </Menu.Item>
                        )}
                    </Menu>
                </div>
                <Button
                    type='primary'
                    style={{alignSelf: 'center', marginBottom: GUTTER[0], borderColor: 'white'}}
                    icon={<LogoutOutlined/>}
                    onClick={() => keycloak.logout()}
                >
                    Logout
                </Button>
            </div>
        </Sider>
    );
};