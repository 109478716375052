import React, {FC, ReactElement, useMemo} from 'react';
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {Card, List, Table, Typography} from "antd";
import {StudentExerciseAttempt} from "../../../../types";
import {ColumnsType} from "antd/lib/table";
import {useLabels} from "../../../../hooks/Labels";
import {DATATABLE_VERTICAL_SCROLL} from "../../../../constants";

const {Text} = Typography;

const columns: ColumnsType<any> = [
    {
        title: 'Section',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        render: value => value + '%'
    },
];

export const SectionItemsList: FC<any> = ({items}): ReactElement => (
    <List
        dataSource={items}
        renderItem={(item: any) => (
            <List.Item>
                <div style={{display: 'flex', justifyContent: "space-between", flexGrow: 1}}>
                    <Text type={item.feedback.matched ? 'success' : 'danger'}>{item.title}</Text>
                    <Text type='secondary'>{`${item.assessment_tag} (${item.assessment_tag_points})`}</Text>
                </div>
            </List.Item>
        )}
    />
)

export const AttemptConversationPerformanceDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();

    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(
        `/reports/student-exercise-attempts/${attemptId}/`, [], {
            defaultFilters: {
                fields: 'id,feedback'
            }
        })

    const sections = useMemo(() => {
        return data?.feedback.stages[0].marking_feedback.sections.map((section: any) => ({
            title: section.title,
            items: section.subsections.reduce((items: any, subsection: any) => items.concat(subsection.items), []),
            score: section.feedback.score,
        })) || [];
    }, [data]);

    return (
        <Card title={t('section_performance_title')} className='data-table-card'>
            <Table
                loading={isLoading}
                dataSource={sections}
                columns={columns}
                rowKey='title'
                pagination={false}
                expandable={{
                    expandedRowRender: record => <SectionItemsList items={record.items}/>,
                    rowExpandable: record => record.items && record.items.length > 0,
                }}
                scroll={DATATABLE_VERTICAL_SCROLL}
            />
        </Card>
    );
};