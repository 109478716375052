import React, {FC, ReactElement} from 'react';
import {FileProtectOutlined} from "@ant-design/icons";
import {useLabels} from "../../../../hooks/Labels";
import {useActivityRenderer} from "../../../../pages/Products/hooks/useActivityRenderer";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {IconCard} from '../../../IconCard';
import {allSearchParamFilters} from "../../../DataTable/Filters/types";
import {ActivityRendererReport} from "../../../../types";


export const AverageScoreCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const renderer = useActivityRenderer();
    const {data, isLoading} = useQuerySearchParams<ActivityRendererReport>(
        `/reports/activity-renderers/${renderer?.id || 0}/`,
        allSearchParamFilters
    )

    return (
        <IconCard
            title={t('average_score_title')}
            isLoading={isLoading}
            value={data?.average_score}
            unit='%'
            icon={<FileProtectOutlined />}
        />
    );
};