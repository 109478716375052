import React, {FC, ReactElement} from 'react';
import {Col, Row} from "antd";

import {useParams} from "react-router-dom";
import {GUTTER} from "../../../constants";
import {OrdersSummaryDataTableCard} from "../components/Investigation/OrdersSummaryDataTableCard";
import {MissedOrdersDataTableCard} from "../components/Investigation/MissedOrdersDataTableCard";
import {UnnecessaryOrdersDataTableCard} from "../components/Investigation/UnnecessaryOrdersDataTableCard";

export const InvestigationRow: FC = (): ReactElement => {
    const {attemptId} = useParams();
    return (
        <Row gutter={GUTTER}>
            {attemptId && <Col xl={18} xs={24}>
                <OrdersSummaryDataTableCard/>
            </Col>}
            <Col xs={24} xl={12}>
                <MissedOrdersDataTableCard/>
            </Col>
            <Col xs={24} xl={12}>
                <UnnecessaryOrdersDataTableCard/>
            </Col>
        </Row>
    );
};