import React, { FC, ReactElement } from 'react';
import {ClockCircleOutlined} from "@ant-design/icons";
import {IconCard} from "../../../IconCard";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {ActivityRendererReport} from "../../../../types";
import {allSearchParamFilters} from "../../../DataTable/Filters/types";
import {formatMinutesDuration} from "../../../../helpers";
import {useLabels} from "../../../../hooks/Labels";
import {useActivityRenderer} from "../../../../pages/Products/hooks/useActivityRenderer";

export const AverageTimeSpentCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const renderer = useActivityRenderer();
    const {data, isLoading} = useQuerySearchParams<ActivityRendererReport>(
        `/reports/activity-renderers/${renderer?.id}/`,
        allSearchParamFilters
    )

    return (
        <IconCard
            title={t('average_time_spent_title')}
            isLoading={isLoading}
            value={data?.avg_completion_time_minutes ? formatMinutesDuration(data.avg_completion_time_minutes) : 'N/A'}
            icon={<ClockCircleOutlined />}
            tooltip={t('average_time_spent_hint')}
        />
    );
};