import React, {ReactElement} from 'react';
import {DatePicker, Form, Space} from "antd";
import moment from "moment";
import {FilterFC} from "./types";
import {OptionSelect} from "./OptionSelect";

const { RangePicker } = DatePicker;

interface Props {
    filters?: ('lt' | 'gt' | 'exact' | 'range')[]
}

const optionLabelMap = (value: string) => {
    switch (value) {
        case 'lt':
            return 'Before';
        case 'gt':
            return 'After';
        case 'exact':
            return 'Exact';
        case 'range':
            return 'Range';
        default:
            return '';
    }
}

const singleFieldFilters = ['lt', 'gt', 'exact'];

export const DateFilter: FilterFC<Props> = ({attribute, title, config}): ReactElement => {
    return (
        <Space direction='vertical'>
            {config?.filters?.length &&
                <OptionSelect name={`${attribute}_option`} filters={config.filters} labelMap={optionLabelMap}/>
            }
            <Form.Item noStyle dependencies={[`${attribute}_option`]}>
                {({getFieldValue}) => singleFieldFilters.includes(getFieldValue(`${attribute}_option`)) ?
                    <Form.Item
                        name={`${attribute}__${getFieldValue(`${attribute}_option`)}`}
                    >
                        <DatePicker/>
                    </Form.Item> :
                    <Form.Item name={`${attribute}__range`}>
                        <RangePicker/>
                    </Form.Item>}
            </Form.Item>
        </Space>
    );
};

DateFilter.formatter = (value) => {
    if (typeof value === 'string') {
        if (value.includes(',')) {
            return value.split(',').map(val => moment(val));
        }
        return moment(value);
    } else if (Array.isArray(value)) {
        return value.map(val => val.format().split('T')[0])
    }
    return value.format().split('T')[0];
};