import React, {FC, ReactElement} from 'react';
import {Col, Row, Typography} from "antd";
import {GUTTER, SHORT_DATETIME_FORMAT} from "../../../constants";
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../config/queryClient";
import {SpinContainer} from "../../../components/SpinContainer";
import moment from "moment";
import {Activity, StudentActivity, StudentExerciseAttempt} from "../../../types";
import {ClockCircleOutlined, FileProtectOutlined} from "@ant-design/icons";
import {IconCard} from "../../../components/IconCard";
import {formatMinutesDuration} from "../../../helpers";
import {
    renderStudentAttemptsFeedback
} from "../../../components/DashboardViews/ActivityFeedbackRows";
import {AttemptRankingCard} from "./AttemptRankingCard";
import {renderActivityScore} from "./RecentActivityAttemptsDataTable";

const {Title} = Typography;

export const StudentAttempt: FC = (): ReactElement => {
    const {id, studentActivityId, attemptId} = useParams();
    const {data: studentActivity, isLoading: studentActivityLoading} = useQuerySearchParams<StudentActivity>(
        `/reports/student-activities/${studentActivityId}/`, []
    );
    const {data: activity, isLoading: activityLoading} = useQuerySearchParams<Activity>(
        `/reports/activities/${studentActivity?.activity.id}/`,
        [], {
            enabled: !!studentActivity,
            defaultFilters: {
                student: id || '',
            }
        })
    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(
        `/reports/student-exercise-attempts/${attemptId}/`,
        [], {
            defaultFilters: {
                fields: 'id,completed_datetime,score,duration,passed'
            }
        });

    if (studentActivityLoading || activityLoading || isLoading) {
        return <SpinContainer/>;
    }

    return (
        <div>
            <Title level={2}
                   style={{fontWeight: 400}}>Attempt {moment(data?.completed_datetime).format(SHORT_DATETIME_FORMAT)}</Title>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={8}>
                    <AttemptRankingCard defaultFilters={{activity: activity?.id || ''}}/>
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title='Score'
                        value={renderActivityScore(data!.score, data!)}
                        icon={<FileProtectOutlined />}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title='Time Spent'
                        value={data?.duration ? formatMinutesDuration(data.duration / 60) : 'N/A'}
                        icon={<ClockCircleOutlined/>}
                    />
                </Col>
            </Row>
            {data?.completed_datetime && renderStudentAttemptsFeedback(activity)}
        </div>
    );
};