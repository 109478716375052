import React, {FC, ReactElement} from 'react';
import {PieChartCard} from "../../../components/PieChartCard";
import {useQuerySearchParams} from "../../../config/queryClient";
import {ActivityAttributeMeta, ActivityAttributeReport} from "../../../types";
import {QueryFilter} from "../../../components/DataTable/Filters/types";

interface Props {
    attributeMeta?: ActivityAttributeMeta
}

export const AttributeValuesDistributionPieChartCard: FC<Props> = ({attributeMeta}): ReactElement => {
    const {data = [], isLoading} = useQuerySearchParams<ActivityAttributeReport[]>(
        `/reports/activity-attributes/`,
        [QueryFilter.COHORTS, QueryFilter.COURSES],
        {defaultFilters: {meta: attributeMeta?.id || ''}}
    );
    return (
        <PieChartCard
        title={attributeMeta?.name || ''}
        angleField='activities_count'
        colorField='value'
        isLoading={isLoading}
        data={data}
    />
    );
};