import React, {FC, ReactElement} from 'react';
import {DataTable} from "../../../components/DataTable/DataTable";
import {Link, useParams} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {StudentCourse} from "../../../types";
import {Progress, Space, Tooltip, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {DATATABLE_VERTICAL_SCROLL, DATETIME_FORMAT} from "../../../constants";
import {ExportButton} from "../../../components/DataTable/ExportButton";
import {Filter, QueryFilter} from "../../../components/DataTable/Filters/types";
import {FilterButton} from "../../../components/DataTable/FilterButton";
import {NumericFilter} from "../../../components/DataTable/Filters/NumericFilter";
import {DateFilter} from "../../../components/DataTable/Filters/DateFilter";
import {useLabels} from "../../../hooks/Labels";

const {Text} = Typography;

const filters: Filter<any>[] = [
    {
        attribute: 'score',
        title: 'Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'completion',
        title: 'Completion',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'enrolled_datetime',
        title: 'Date enrolled',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt']
        },
    },
];

const columns: ColumnsType<StudentCourse> = [
    {
        title: 'Name',
        dataIndex: ['course', 'title'],
        key: 'course.title',
        sorter: true,
        render: (text, record) => <Link to={`course/${record.id}`}>{text}</Link>
    },
    {
        title: <Space>
            <Text>Score</Text>
            <Tooltip placement='bottom'
                     title='The score of uncompleted courses is based on the average performance so far'>
                <InfoCircleOutlined/>
            </Tooltip>
        </Space>,
        dataIndex: 'score',
        key: 'score',
        render: (value, record) => {
            if (value) {
                return <strong>{value}%</strong>;
            } else if (record['provisional_score']) {
                return `${record['provisional_score']}%`
            } else return '-';
        },
        sorter: true
    },
    {
        title: 'Completion',
        dataIndex: 'completion',
        key: 'completion',
        render: (value) => <Progress percent={value}/>,
        sorter: true
    },
    {
        title: 'Date enrolled',
        dataIndex: 'enrolled_datetime',
        key: 'enrolled_datetime',
        render: (value) => value ? moment(value).format(DATETIME_FORMAT) : 'Not enrolled',
        sorter: true
    },
];

export const EnrolledCoursesDataTable: FC = (): ReactElement => {
    const {id} = useParams();
    return (
        <DataTable
            columns={columns}
            query='/reports/student-courses/'
            pagination={false}
            searchParamFilters={[QueryFilter.COHORTS, QueryFilter.COURSES]}
            defaultFilters={{
                student: id || ''
            }}
            scroll={DATATABLE_VERTICAL_SCROLL}
        />
    );
};

export const EnrolledCoursesDataTableFiltering: FC = (): ReactElement => {
    const {t} = useLabels();
    const {id} = useParams();
    return (
        <Space wrap>
            <ExportButton
                url='/reports/student-courses'
                filenamePrefix={`${t('student', false)}-enrolled-courses`}
                defaultFilters={{student: id || ''}}
                searchParamFilters={[QueryFilter.COHORTS, QueryFilter.COURSES]}
            />
            <FilterButton filters={filters}/>
        </Space>
    );
}