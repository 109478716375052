import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import browserHistory from "./browserHistory";
import appConfig from "./config"

if(appConfig.SENTRY_DSN) {
  Sentry.init({
    dsn: appConfig.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({

        routingInstrumentation:
            // @ts-ignore
            Sentry.reactRouterV5Instrumentation(browserHistory),
      }),
    ],
    environment: appConfig.REACT_APP_ENV,
    // No point to send since "your organization's plan does not include performance monitoring".
    tracesSampleRate: 0,
  });
}
