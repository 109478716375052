import React, {FC, ReactElement} from 'react';
import {Link, useParams} from "react-router-dom";
import {Course} from "../../../types";
import {ColumnsType} from "antd/lib/table";
import {DataTable} from "../../../components/DataTable/DataTable";
import {DATATABLE_VERTICAL_SCROLL} from "../../../constants";

const columns: ColumnsType<Course> = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => <Link to={`/courses/${record.id}?courses=${record.id}`}>{text}</Link>,
        sorter: true
    },
];

export const NotEnrolledCoursesDataTable: FC = (): ReactElement => {
    const {id} = useParams();

    return (
        <DataTable
            query={`/reports/students/${id}/not-enrolled-courses/`}
            columns={columns}
            pagination={false}
            scroll={DATATABLE_VERTICAL_SCROLL}
        />
    );
};