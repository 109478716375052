import {useProduct} from "./useProduct";
import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import {ActivityRenderer} from "../../../types";

export const useActivityRenderer = (): ActivityRenderer | undefined => {
    const product = useProduct();
    const location = useLocation();

    const activeTab = useMemo(() => location.pathname.split('/').at(-1), [location]);

    return useMemo(() =>
        product?.activity_renderers.find(renderer => renderer.slug === activeTab), [product, activeTab]);
}