import React, {FC, Fragment, ReactElement, useMemo} from 'react';
import {Activity, StudentActivity} from "../../../types";
import {Col, Row} from "antd";
import {DurationHistogramCard} from "../components/DurationHistogramCard";
import {useParams} from "react-router-dom";
import {StudentPerformanceDataTableCard} from "../components/StudentPerformanceDataTableCard";
import {GUTTER} from "../../../constants";
import {renderAttemptsFeedback} from "../../../components/DashboardViews/ActivityFeedbackRows";
import {useQuerySearchParams} from "../../../config/queryClient";
import {QueryFilter} from "../../../components/DataTable/Filters/types";
import {HistogramChartCard} from "../../../components/HistogramChartCard";
import {useLabels} from "../../../hooks/Labels";

export interface ActivityViewProps {
    activity: Activity,
}

export const Exercise: FC<ActivityViewProps> = ({activity}): ReactElement => {
    const {t} = useLabels();
    const {activityId} = useParams();
    const {data: attempts, isLoading: attemptsLoading} = useQuerySearchParams<StudentActivity[]>(
        '/reports/student-activities/', [QueryFilter.COHORTS], {
            defaultFilters: {
                activity: activityId || '',
                fields: 'id,best_score,best_attempt_duration_minutes'
            }
        })

    const scoreHistogramData = useMemo(() => attempts?.map(attempt => attempt.best_score).filter(score => score != null), [attempts]);
    const durationHistogramData = useMemo(() => attempts?.filter(item => item !== null).map(attempt => attempt.best_attempt_duration_minutes) || [], [attempts]);

    return (
        <Fragment>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={12}>
                    <HistogramChartCard
                        title='Score'
                        label={t('student_pl')}
                        // @ts-ignore
                        data={scoreHistogramData}
                        unit='%'
                        isLoading={attemptsLoading}
                    />
                </Col>
                <Col xs={24} xl={12}>
                    <DurationHistogramCard
                        isLoading={attemptsLoading}
                        data={durationHistogramData}
                        average={activity?.avg_completion_time_minutes}
                    />
                </Col>
            </Row>
            <div style={{marginTop: GUTTER[0]}}>{renderAttemptsFeedback(activity)}</div>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={24}>
                    <StudentPerformanceDataTableCard/>
                </Col>
            </Row>
        </Fragment>
    );
};