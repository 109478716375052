import React, {FC, ReactElement, useMemo} from 'react';
import {CheckCircleTwoTone, FileTextOutlined} from "@ant-design/icons";
import {IconCard} from "../../../components/IconCard";
import {Activity, ActivityType} from "../../../types";
import {useLabels} from "../../../hooks/Labels";

interface Props {
    activity: Activity
}

const getLabel = (type: ActivityType) => {
    switch (type) {
        case ActivityType.EXERCISE:
            return 'Attempted by';
        case ActivityType.WRITING_ASSIGNMENT:
            return 'Submitted by';
        default:
            return 'Completed by';
    }
}

export const ActivityAttemptsCard: FC<Props> = ({activity}): ReactElement => {
    const {t} = useLabels();
    const isReadingOrVideo = useMemo(() =>
            activity.type === ActivityType.READING ||
            activity.type === ActivityType.VIDEO,
        [activity]);
    return (
        <IconCard
            title={getLabel(activity.type)}
            value={`${activity.students_attempted || '0'} ${t('student_pl', false)}`}
            icon={isReadingOrVideo ?  <CheckCircleTwoTone /> : <FileTextOutlined />}
        />
    );
};