import {FC, Fragment, ReactElement, useCallback, useMemo} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {useProduct} from "./hooks/useProduct";
import {ExerciseDashboardViews} from "../../types";
import {Col, Row, Space, Tabs, Typography} from "antd";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {SearchParamFilterSelect} from "../../components/SearchParamFilterSelect";
import './style.css';
import {ActivityAttributeReportRow} from "./components/ActivityAttributeReportRow";
import {ActivitiesDataTableCard, activityColumns, activityFilters} from "../../components/ActivitiesDataTableCard";
import {GUTTER} from "../../constants";
import {QueryFilter} from "../../components/DataTable/Filters/types";
import {useActivityRenderer} from "./hooks/useActivityRenderer";
import {LabelsProvider, useLabels} from "../../hooks/Labels";

const {Title} = Typography;
const { TabPane } = Tabs;

export const ProductPage: FC = (): ReactElement => {
    const {t} = useLabels();
    const product = useProduct();
    const navigate = useNavigate();
    const location = useLocation();
    const renderer = useActivityRenderer();

    const activeTabKey = useMemo(() => location.pathname.split('/').at(-1), [location]);

    const onTabClick = useCallback((key: string) =>
        navigate(`/${product?.slug}/${key}`), [navigate, product]);

    const exerciseActivityRenderers = useMemo(() => product?.activity_renderers
        .filter(renderer => ExerciseDashboardViews.includes(renderer.dashboard_view)) || [], [product]);

    return (
        <Fragment>
            <PageHeader title={product?.name}/>
            <ActivityAttributeReportRow product={product}/>
            <div className='tabs-filters-container'>
                {
                    exerciseActivityRenderers.length > 1 ?
                        <Tabs className='tabs-container' activeKey={activeTabKey} onChange={onTabClick}>
                            {exerciseActivityRenderers.map(renderer =>
                                <TabPane key={renderer.slug} tab={renderer.name}/>)}
                        </Tabs> :
                        <Title level={2} style={{fontWeight: 400}}>
                            {t('product_breakdown_single_renderer_title')}
                        </Title>
                }
                <Space wrap>
                    <SearchParamFilterSelect
                        query={`/management/activity-attributes/?is_primary=true&meta=${product?.primary_attribute_meta.id}`}
                        searchParam={QueryFilter.PRIMARY_ATTRIBUTES}
                        style={{width: '100%', minWidth: 200}}
                        placeholder={product?.primary_attribute_meta?.name}
                        optionMap={({id, value}) => ({label: value, value: id})}
                    />
                    <SearchParamFilterSelect
                        query={`/management/activity-attributes/?meta_exclude=${product?.primary_attribute_meta.id}`}
                        searchParam={QueryFilter.NON_PRIMARY_ATTRIBUTES}
                        style={{width: '100%', minWidth: 200}}
                        placeholder={t('non_primary_attributes_name')}
                        optionMap={({id, value}) => ({label: value, value: id})}
                    />
                </Space>
            </div>
            <LabelsProvider activityRenderer={renderer?.id}>
                <Outlet/>
            </LabelsProvider>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={24}>
                    {renderer && <ActivitiesDataTableCard
                        defaultFilters={{renderer: renderer.dashboard_view}}
                        columns={[activityColumns[0], ...activityColumns.slice(2)]}
                        filters={activityFilters.slice(0, -1)}
                        searchParamFilters={[
                            QueryFilter.COHORTS,
                            QueryFilter.COURSES,
                            QueryFilter.PRIMARY_ATTRIBUTES,
                            QueryFilter.NON_PRIMARY_ATTRIBUTES
                        ]}
                    />}
                </Col>
            </Row>
        </Fragment>
    );
};