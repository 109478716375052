import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {Col, Row} from "antd";
import {GUTTER} from "../../constants";
import {BookOutlined, FileOutlined, UserOutlined} from "@ant-design/icons";
import {IconCard} from "../../components/IconCard";
import {useQuery} from "react-query";
import {ExerciseDashboardViews, OrganisationOverview} from "../../types";
import {useOrganisation} from "../../layout/OrganisationInformationProvider";
import {AttributePerformanceColumnChartCard} from "../Products/components/AttributePerformanceColumnChartCard";
import {ActivityRendererPerformanceDataTableCard} from "./components/ActivityRendererPerformanceDataTableCard";
import {StudentEngagementLineChartCard} from "./components/StudentEngagementLineChartCard";
import {FilterPrefixProvider} from "../../components/DataTable/Filters/FilterPrefixProvider";
import {Link} from "react-router-dom";
import {LabelsProvider, useLabels} from "../../hooks/Labels";
import {ActivityRendererOverviewCardMapping} from "../../components/DashboardViews/OverviewCards";


export const OverviewPage: FC = (): ReactElement => {
    const {products} = useOrganisation();
    const {t} = useLabels();
    const {data, isLoading} = useQuery<OrganisationOverview>('/reports/overview');

    return (
        <Fragment>
            <PageHeader title='Overview'/>
            <Row gutter={GUTTER}>
                <Col xl={12} xs={24} style={{display: 'block'}}>
                    <Row gutter={GUTTER}>
                        <Col xl={12} xs={24}>
                            <IconCard
                                title={t('student_pl')}
                                isLoading={isLoading}
                                value={data?.students}
                                icon={<UserOutlined />}
                                href='/students'
                            />
                        </Col>
                        <Col xl={12} xs={24}>
                            <IconCard
                                title={t('course_pl')}
                                isLoading={isLoading}
                                value={data?.courses}
                                icon={<BookOutlined />}
                                href='/courses'
                            />
                        </Col>
                        <Col xl={12} xs={24}>
                            <IconCard
                                isLoading={isLoading}
                                title='Monthly active users'
                                value={data?.active_users_past_month}
                                icon={<UserOutlined />}
                            />
                        </Col>
                        <Col xl={12} xs={24}>
                            <IconCard
                                title={t('activity_pl')}
                                isLoading={isLoading}
                                value={data?.activities}
                                icon={<FileOutlined />}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xl={12} xs={24}>
                    <FilterPrefixProvider prefix='engagement'>
                        <StudentEngagementLineChartCard/>
                    </FilterPrefixProvider>
                </Col>
            </Row>
            {products && products.map(product => {
                const renderers = product.activity_renderers.filter(renderer =>
                    ExerciseDashboardViews.includes(renderer.dashboard_view)
                );
                const shouldUseRendererView
                    = renderers.length === 1 && (renderers[0].dashboard_view in ActivityRendererOverviewCardMapping)
                return (
                    <div key={product.id} style={{marginTop: GUTTER[0]}}>
                        <Link to={`/${product.slug}`} style={{fontSize: 36, fontWeight: 400}}>{product.name}</Link>
                        <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                            <Col xl={14} xs={24}>
                                <AttributePerformanceColumnChartCard attributeMeta={product.primary_attribute_meta}/>
                            </Col>
                            <Col xl={10} xs={24}>
                                {
                                    shouldUseRendererView
                                    ? <LabelsProvider activityRenderer={renderers[0].id}>
                                        {ActivityRendererOverviewCardMapping[renderers[0].dashboard_view]}
                                    </LabelsProvider>
                                    : <ActivityRendererPerformanceDataTableCard product={product}/>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            })}
        </Fragment>
    );
};