import React, { FC, ReactElement } from 'react';
import {Form, Select} from "antd";

const {Option} = Select;

interface Props {
    name: string,
    filters: string[],
    labelMap: (value: string) => string
}

export const OptionSelect: FC<Props> = ({name, filters, labelMap}): ReactElement => {
    return (
        <Form.Item name={name}>
            <Select>
                {filters?.map(option =>
                    <Option key={option} value={option}>
                        {labelMap(option)}
                    </Option>
                )}
            </Select>
        </Form.Item>
    );
};