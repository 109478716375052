import React, {FC, ReactElement, useMemo} from 'react';
import {Placeholder, SelectFilter} from "./SelectFilter";
import {Button, Col, Grid, Layout as AntLayout, Row} from "antd";
import {BookOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from "@ant-design/icons";
import {matchRoutes, useLocation} from "react-router-dom";
import {FilterRouteObject, useRoutesWithProducts} from "../config/routes";
import {QueryFilter} from "../components/DataTable/Filters/types";

const {Header: AntHeader} = AntLayout;
const {useBreakpoint} = Grid;

const HamburgerMenuButton: FC<Props> = ({collapsed, onCollapse}): ReactElement => (
    <Button type='text' className='trigger' onClick={() => onCollapse((collapsed) => !collapsed)}>
        {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
    </Button>
)

interface Props {
    collapsed: boolean,
    onCollapse: React.Dispatch<React.SetStateAction<boolean>>
}

const ColStyle = {
    height: '100%',
    borderRight: '1px solid black',
    padding: '5px',
    alignItems: 'center'
}

export const Header: FC<Props> = ({collapsed, onCollapse}): ReactElement | null => {
    const screens = useBreakpoint();
    const location = useLocation();
    const routes = useRoutesWithProducts();
    const [cohortFilter, courseFilter] = useMemo(() => {
        const match = matchRoutes(routes, location)?.at(-1);
        if (match?.route) {
            const route = match.route as FilterRouteObject;
            return [route?.cohortFilter, route?.courseFilter];
        }
        return [false, false];
    }, [location, routes]);

    if (!cohortFilter && !courseFilter) {
        return <Row className='header-container' align='middle'>
            {!screens.lg && <HamburgerMenuButton collapsed={collapsed} onCollapse={onCollapse}/>}
        </Row>;
    }

    return (
        <AntHeader className='header-background header-container'>
            <Row wrap={false} align='middle' className='w-100 h-100'>
                {!screens.lg
                    && <Col style={ColStyle}>
                        <HamburgerMenuButton collapsed={collapsed} onCollapse={onCollapse}/>
                    </Col>
                }
                <Col flex='auto' className='w-100 h-100'>
                    <Row wrap={false} className='w-100 h-100'>
                        <Col span="12" style={ColStyle}>
                            {
                                cohortFilter
                                && <SelectFilter
                                    query='/management/cohorts/?fields=id,name'
                                    searchParam={QueryFilter.COHORTS}
                                    optionMap={({id, name}) => ({value: id, label: name})}
                                    placeholder={<Placeholder icon={UserOutlined} label='Cohorts'/>}
                                    {...cohortFilter}
                                />
                            }
                        </Col>
                        <Col span="12" style={ColStyle}>
                            {
                                courseFilter
                                && <SelectFilter
                                    query='/management/courses/?fields=id,title'
                                    searchParam={QueryFilter.COURSES}
                                    optionMap={({id, title}) => ({value: id, label: title})}
                                    placeholder={<Placeholder icon={BookOutlined} label='Courses'/>}
                                    {...courseFilter}
                                />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </AntHeader>
    );
}