import React, {FC, ReactElement} from 'react';
import {Card, Empty, Spin} from "antd";
import {Column, ColumnConfig} from "@ant-design/charts";

interface Props extends ColumnConfig {
    title: string,
    isLoading?: boolean,
}

const GlitchyColumnChartCard: FC<Props> = ({title, data, isLoading, ...rest}): ReactElement => {
    // It's glitchy because the graph repaints on every parent state update
    return (
        <Card title={title} className='chart-card'>
            {isLoading ? <Spin/> :
                data.length > 0 ? <Column
                    data={data}
                    {...rest}
                /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card>
    );
};

export const ColumnChartCard = React.memo(GlitchyColumnChartCard);