import React, {FC, Fragment, ReactElement} from 'react';
import {Col} from "antd";
import {AttemptsMenuCard} from "../components/AttemptsMenuCard";
import {Outlet} from "react-router-dom";

export const Exercise: FC = (): ReactElement => {
    return (
        <Fragment>
            <Col xs={24} xl={6}>
                <AttemptsMenuCard/>
            </Col>
            <Col xs={24} xl={18} style={{display: 'block'}}>
                <Outlet/>
            </Col>
        </Fragment>
    );
};