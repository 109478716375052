export interface PagedResponse {
    count: number,
    next: string | null,
    previous: string | null,
    results: Model[]
}

export interface Model {
    id: number
}

export interface Cohort extends Model {
    name: string,
    invitation_code: string,
    invitation_url: string,
    total_students: number,
    remaining_seats: number,
    average_score: number,
    course_completion: number,
    total_courses: number,
    total_students_completed_all_courses: number,
    average_time_spent: number
}

export interface BaseCourse extends Model {
    title: string
}

export interface Course extends BaseCourse {
    date_published: string,
    average_score?: number,
    description?: string,
    enrollment_rate_percentage: number,
    completion_rate_percentage: number,
    total_attempts: number,
    total_enrolled_students: number,
    primary_attributes: ActivityAttribute[]
}

export interface StudentCourse extends Model {
    score: number | null,
    provisional_score: number | null,
    enrolled_datetime: string,
    completed_datetime: string | null,
    completion: number,
    course: BaseCourse
}

export interface StudentRanking extends Model {
    rank: number,
    total: number
}

export interface ActivityAttributeMeta extends Model {
    name: string,
    is_primary: boolean
}

export interface ActivityAttribute extends Model {
    id: number,
    value: string,
    meta: ActivityAttributeMeta
}

export interface ActivityAttributeReport extends ActivityAttribute {
    activities_count: number,
    avg_score: number | null
}

export enum ActivityType {
    EXERCISE = 0,
    READING = 1,
    VIDEO = 2,
    WRITING_ASSIGNMENT = 3,
}

export interface Section extends Model {}

export interface BaseActivity extends Model {
    title: string
}

export interface SimpleActivity extends BaseActivity {
    type: ActivityType,
    renderers: BaseActivityRenderer[],
    primary_attribute: ActivityAttribute,
}

export interface Activity extends SimpleActivity {
    description?: string,
    estimated_time_minutes: number,
    average_score?: number,
    students_attempted: number,
    section: Section['id'],
    primary_attribute: ActivityAttribute,
    avg_completion_time_minutes: number | null,
    renderers: BaseActivityRenderer[],
    course: BaseCourse
}

export interface BaseStudentExerciseAttempt extends Model {
    score: number,
    activity: BaseActivity,
    completed_datetime: string | null,
    student_activity: number
}

export interface StudentExerciseAttempt extends BaseStudentExerciseAttempt {
    exercise_session_id: string,
    duration: number, // in seconds
    passed: boolean,
    started_datetime: string,
    completed_datetime: string,
    course: number,
    student_activity: number,
    transcript_html?: string,
    feedback?: any
}

export interface BaseStudent extends Model {
    full_name: string,
    email: string
}

export interface Student extends BaseStudent {
    average_score: number | null,
    course_completion: number,
    attempts: number,
    last_active: string | null,
    latest_attempt: string | null,
    available_courses: number
}

export interface StudentWritingAssignmentActivity extends Model {
    content: any,
    submitted_at: string
}

export interface StudentActivityEngagement {
    date: string,
    total: number
}

export interface StudentActivity extends Model {
    completed: boolean,
    best_score: number | null,
    best_attempt_duration_minutes: number,
    student: BaseStudent,
    time_to_complete_minutes: number | null,
    completed_datetime: string | null,
    activity: BaseActivity,
    created_at: string
}

export interface StudentExercisePerformance extends Model {
    first_score: number,
    best_score: number,
    completed: boolean,
    activity: SimpleActivity,
    attempts: number,
    last_attempt_datetime: string,
    student: BaseStudent
}

export enum DashboardView {
    ACTIVITY_NON_GRADED = 0,
    ACTIVITY_WRITING = 1,
    EXERCISE_CONVERSATION_STORY_COVERAGE = 2,
    EXERCISE_CONVERSATION_ELICIT_PERFORMANCE = 3,
    EXERCISE_DIALOGUE_CHOICES_COVERAGE = 4,
    EXERCISE_PHYSICAL_EXAM_COVERAGE = 5,
    EXERCISE_INVESTIGATIONS_COVERAGE = 6,
    EXERCISE_TRANSCRIPT_COVERAGE = 7,
}

export const ExerciseDashboardViews = [
    DashboardView.EXERCISE_INVESTIGATIONS_COVERAGE,
    DashboardView.EXERCISE_PHYSICAL_EXAM_COVERAGE,
    DashboardView.EXERCISE_DIALOGUE_CHOICES_COVERAGE,
    DashboardView.EXERCISE_CONVERSATION_STORY_COVERAGE,
    DashboardView.EXERCISE_CONVERSATION_ELICIT_PERFORMANCE,
    DashboardView.EXERCISE_TRANSCRIPT_COVERAGE
];

export interface BaseActivityRenderer extends Model {
    name: string,
    dashboard_view: DashboardView,
}

export interface ActivityRenderer extends BaseActivityRenderer {
    slug:  string,
    theme_label_overrides: any,
}

export interface ActivityRendererReport extends Model {
    average_score: number,
    avg_completion_time_minutes: number,
}

export interface DashboardProduct extends Model {
    name: string,
    slug: string,
    primary_attribute_meta: ActivityAttributeMeta,
    activity_renderers: ActivityRenderer[]
}

export interface InvestigationItem {
    name: string,
    cost: string,
    frequency: number,
    num_occurrences: number,
    time_estimated: string
}

export interface HistoryItem {
    title: string,
    num_occurrences: number,
    tag: string
}

export interface HistorySection {
    title: string,
    score: number | null
}

export enum DialogueChoiceAnswerTag {
    OPTIMAL = 'OPTIMAL',
    SUB_OPTIMAL = 'SUB_OPTIMAL',
    NEUTRAL = 'NEUTRAL',
    NEAR_OPTIMAL = 'NEAR_OPTIMAL',
    INCORRECT = 'INCORRECT'
}

export interface DialogueChoiceAnswer {
    tag: DialogueChoiceAnswerTag,
    num_occurrences: number
}

export interface ConversationMissedItems {
    avg_missed_items: number,
}

export interface OrganisationOverview extends Model {
    students: number,
    courses: number,
    activities: number,
    active_users_past_month: number
}