import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {BookOutlined, CheckCircleOutlined, UserOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import {GUTTER} from "../../constants";
import {IconCard} from "../../components/IconCard";
import {useParams} from "react-router-dom";
import {QueryFilter} from "../../components/DataTable/Filters/types";
import {useQuerySearchParams} from "../../config/queryClient";
import {Student} from "../../types";
import {SpinContainer} from "../../components/SpinContainer";
import {RecentActivityAttemptsCard} from "./components/RecentActivityAttemptsCard";
import {CoursesDataTableCard} from "./components/CoursesDataTableCard";
import {StudentRankingCard} from "./components/StudentRankingCard";
import { useLabels } from '../../hooks/Labels';


export const StudentPage: FC = (): ReactElement => {
    const {t} = useLabels();
    const {id} = useParams();
    const {data, isLoading} = useQuerySearchParams<Student>(
        `/reports/students/${id}/`,
        [QueryFilter.COURSES, QueryFilter.COHORTS]
    );

    if (isLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <PageHeader title={data?.full_name} icon={UserOutlined}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={8}>
                    <StudentRankingCard/>
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title='Overall Score'
                        isLoading={isLoading}
                        value={data?.average_score}
                        unit='%'
                        icon={<BookOutlined />}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <IconCard
                        title={`${t('course')} completion`}
                        isLoading={isLoading}
                        value={data?.course_completion || 0}
                        unit='%'
                        icon={<CheckCircleOutlined />}
                        description={data?.available_courses ? `out of ${data.available_courses}` : undefined}
                    />
                </Col>
            </Row>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={14}>
                    <CoursesDataTableCard/>
                </Col>
                <Col xs={24} xl={10}>
                    <RecentActivityAttemptsCard/>
                </Col>
            </Row>
        </Fragment>
    );
};