import React, {useMemo} from "react";
import {Activity} from "../../../types";
import {ActivityTypeNames} from "../../../constants";
import {BookOutlined} from "@ant-design/icons";
import {SubtitleItemProps} from "../../../components/PageHeader";

export const useActivitySubtitle = (activity?: Activity) => {
    return useMemo(() => {
        if (!activity) return undefined;
        const items: SubtitleItemProps[] = [];
        items.push({
            title: activity.course.title,
            icon: <BookOutlined/>
        });
        items.push({
            title: activity.renderers.length > 0 ?
                activity.renderers.map(renderer => renderer.name).join(', ') :
                ActivityTypeNames[activity.type]
        })
        if (activity.primary_attribute) {
            items.push({
                title: activity.primary_attribute.value
            })
        }
        return items;
    }, [activity]);
}