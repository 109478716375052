import React, { FC, ReactElement } from 'react';
import {ColumnsType} from "antd/lib/table";
import {InvestigationItem} from "../../../../types";
import {useParams} from "react-router-dom";
import {StudentExerciseAttemptReportDataTableCard} from "../Generic/StudentExerciseAttemptReportDataTableCard";
import {useLabels} from "../../../../hooks/Labels";

export const columns: ColumnsType<InvestigationItem> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Frequency',
        dataIndex: 'frequency',
        key: 'frequency',
        render: (text) => `${text}%`
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
    },
    {
        title: 'Duration Est.',
        dataIndex: 'time_estimated',
        key: 'time_estimated',
    },
]

export const MissedOrdersDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();
    return (
        <StudentExerciseAttemptReportDataTableCard
            title={t('missed_items_title')}
            columns={attemptId ? columns.filter(column => column.key !== 'frequency') : columns}
            query='/reports/investigation/missed-order-items/'
        />
    )
};