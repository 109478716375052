import React, { FC, ReactElement } from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

interface Props {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}

export const ServerErrorPage: FC<Props> = ({resetError}): ReactElement => {
    const navigate = useNavigate();

    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={<Button type="primary" onClick={() => {
                navigate('/');
                resetError();
            }}>Back Home</Button>}
        />
    );
};