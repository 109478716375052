import React, {FC, ReactElement, useState} from 'react';
import {Space, Typography} from 'antd';
import {SearchParamFilterSelect, SearchParamFilterSelectProps} from "../components/SearchParamFilterSelect";

const { Text } = Typography;

interface PlaceholderProps {
    icon: FC<any>,
    label: string
}

export const Placeholder: FC<PlaceholderProps> = ({icon: Icon, label}) => (
    <Space direction='horizontal'>
        <Icon style={{fontSize: 24, color: '#000'}}/>
        <Text style={{fontSize: 24}}>{label}</Text>
    </Space>
)

export const SelectFilter: FC<SearchParamFilterSelectProps<any>> = ({mode = 'multiple', ...rest}): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <SearchParamFilterSelect
            className={`top-filter-${mode}-select`}
            onDropdownVisibleChange={setOpen}
            bordered={false}
            style={{
                width: '100%',
                textAlign: open ? 'start' : 'center',
            }}
            mode={mode}
            {...rest}
        />
    )
}