import {createContext, FC, ReactElement, useCallback, useContext, useMemo} from "react";
import {useOrganisation} from "../layout/OrganisationInformationProvider";
import {ActivityRenderer} from "../types";

export const LabelsContext = createContext<ActivityRenderer['id'] | undefined>(undefined);

interface Props {
    activityRenderer?: ActivityRenderer['id']
}

export const LabelsProvider: FC<Props> = ({children, activityRenderer}): ReactElement => (
    <LabelsContext.Provider value={activityRenderer}>
        {children}
    </LabelsContext.Provider>
);

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const useLabels = () => {
    const organisation = useOrganisation();
    const allActivityRenderers = useMemo(() => organisation.products?.reduce(
            (renderers, product) => renderers.concat(product.activity_renderers), [] as ActivityRenderer[])
        , [organisation]);
    const rendererId = useContext(LabelsContext);
    const activityRenderer = useMemo<ActivityRenderer | undefined>(() =>
            allActivityRenderers?.find(activityRenderer => activityRenderer.id === rendererId),
        [allActivityRenderers, rendererId]);

    const t = useCallback((label: string, capitalizeFirstLetter = true) => {
        let text = organisation?.labels?.[label];
        if (activityRenderer && activityRenderer.theme_label_overrides[label]) {
            text = activityRenderer.theme_label_overrides[label];
        }
        text = text ?? label;
        return capitalizeFirstLetter ? capitalize(text) : text;
    }, [organisation, activityRenderer]);
    return {t};
}