import React, {FC, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {Select, SelectProps} from 'antd';
import {useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";
import {FilterProps} from "../config/routes";

interface OptionProps {
    label: string,
    value: string | number
}

export interface SearchParamFilterSelectProps<T> extends Omit<SelectProps<T>, 'mode'> {
    query: string,
    searchParam: string,
    optionMap: (item: T) => OptionProps,
    mode?: FilterProps['mode']
}

export const SearchParamFilterSelect: FC<SearchParamFilterSelectProps<any>> = ({query, searchParam, placeholder, mode = 'multiple', optionMap, onDropdownVisibleChange = () => {}, ...rest}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [selectionMade, setSelectionMade] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const {data, isLoading, isFetched} = useQuery<object[]>(query);

    const handleChange = useCallback((values: (number | string)[]) => {
        if (!Array.isArray(values)) values = [values]; // Single select
        if (values.includes('all')) {
            setSelected(data?.map(optionMap).map((option) => option.value) || []);
            setOpen(false);
        } else {
            setSelected(values);
        }
        setSelectionMade(true);
    }, [data, optionMap]);

    // Initialise selected from query params
    useEffect(() => {
        if (!selectionMade && isFetched && selected.length === 0) {
            if (searchParams.has(searchParam)) {
                setSelected(searchParams.get(searchParam)?.split(',').map((id: string) => parseInt(id)) || [])
                setSelectionMade(true);
            }
        }
    }, [isFetched, searchParam, searchParams, selected, selectionMade]);

    useEffect(() => {
        const searchParamsList = Object.fromEntries(searchParams.entries());
        for (let key in searchParamsList) {
            if (key.endsWith('page')) {
                searchParams.delete(key);
            }
        }
        setSearchParams(searchParams, {replace: true});
        // The linter will complain about 'searchParams' and 'setSearchParams',
        // but we only want the effect to run when the selected list changes
    }, [selected])

    // Update query params with selected options on dropdown close
    useEffect(() => {
        if (!selectionMade || open) {
            return;
        }
        if (selected.length > 0) {
            searchParams.set(searchParam, selected.join(','));
        } else {
            searchParams.delete(searchParam);
        }
        setSearchParams(searchParams, {replace: true});
    }, [open, searchParams, setSearchParams, selected, searchParam, selectionMade]);

    useEffect(() => {
        onDropdownVisibleChange(open);
    }, [onDropdownVisibleChange, open])

    const options = useMemo(() => {
        const options = data?.map(optionMap) || [];
        if (mode !== 'single') {
            return [{value: 'all', label: 'Select All'}, ...options];
        }
        return options;
    }, [mode, data, optionMap]);

    return (
        <Select
            open={open}
            value={selected}
            mode={mode === 'single' ? undefined : mode}
            showSearch
            loading={isLoading}
            allowClear
            removeIcon={null} // Disable item removal
            showArrow
            defaultActiveFirstOption={false}
            placeholder={open ? 'Search...' : placeholder}
            onChange={handleChange}
            maxTagCount='responsive'
            optionFilterProp='label'
            options={options}
            // Track state of dropdown
            // Needed in order to update query params on dropdown close
            onDropdownVisibleChange={(open) => setOpen(open)}
            {...rest}
        >
        </Select>
    );
};