import { FC, ReactElement } from 'react';
import {ColumnsType} from "antd/lib/table";
import {HistorySection} from "../../../../types";
import {
    StudentExerciseAttemptReportDataTableCard,
} from "./StudentExerciseAttemptReportDataTableCard";
import {useLabels} from "../../../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";
import {useParams} from "react-router-dom";

const columns = (isAggregated: boolean): ColumnsType<HistorySection> => {
    return [
        {
            title: 'Section',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: isAggregated ? 'Avg. Score' : 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (text) => isNumeric(text) ? `${text}%` : 'N/A'
        },
    ]
}
    

interface Props {
    type: 'conversation' | 'dialogue-choices',
}

export const SectionPerformanceDataTableCard: FC<Props> = ({type}): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();
    const isAggregated = !attemptId;

    return (
        <StudentExerciseAttemptReportDataTableCard
            title={isAggregated ? t('average_section_performance_title') : t('section_performance_title')}
            columns={columns(isAggregated)}
            query={`/reports/${type}/section-performance/`}
        />
    )
};