import React from 'react';

import {Divider, Row, Space, Typography} from "antd";
import {FeedbackModal, FeedbackModalFC} from "../Generic/FeedbackModal";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {useParams} from "react-router-dom";
import {StudentExerciseAttempt} from "../../../../types";
import {SpinContainer} from "../../../SpinContainer";

const {Text} = Typography;


interface Action<PayloadType> {
    id: number;
    action_type: 'utterance' | 'resume' | 'start';
    payload: PayloadType;
}

interface AgentActionPayload {
    textual: {
        text: string;
    };
}

interface UserActionPayload {
    text: string;
}

interface Event {
    created_at: string;
    agent_action?: Action<AgentActionPayload>;
    user_action?: Action<UserActionPayload>;
}


const MsgBox: React.FC<{ style?: React.CSSProperties }> = props => {
    return (
        <Text
            style={{
                borderRadius: '15px',
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
                padding:'0.75rem',
                ...props.style
            }}
        >
            {props.children}
        </Text>
    );
};

type TranscriptEventFC = React.FC<{event: Event}>;

const getHumanReadableFromDate = (date: string) =>
    `${new Date(date).toLocaleDateString()} - ${new Date(
        date,
    ).toLocaleTimeString()}`;

const ResumeEvent: TranscriptEventFC = ({event}) => (
    <Divider>
        <Text>
            Conversation resumed
        </Text>
        <Text>
            {getHumanReadableFromDate(event.created_at)}
        </Text>
    </Divider>
);

const UserMessageEvent: TranscriptEventFC = ({event}) => (
    <Row
        justify='end'
    >
        <MsgBox
            style={{
                backgroundColor: 'black',
                color: 'white',
                maxWidth: '80%'
            }}
        >
            {event.user_action!.payload.text}
        </MsgBox>
    </Row>
);

const AgentMessage: TranscriptEventFC = ({event}) => (
    <Row
        justify='start'
    >
        <MsgBox style={{backgroundColor: 'var(--grey-200)', maxWidth: '80%'}}>
            {event.agent_action!.payload.textual.text}
        </MsgBox>
    </Row>
);


export const TranscriptModal: FeedbackModalFC<any> = () => {
    const {attemptId} = useParams();
    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(`/reports/student-exercise-attempts/${attemptId}/`, [], {
        defaultFilters: {
            fields: 'id,feedback'
        }
    })

    return (
        <FeedbackModal title={TranscriptModal.title}>
            {
                isLoading
                ? <SpinContainer/>
                : <Space direction={'vertical'} size='middle'>
                    {
                        data!.feedback.stages[0].events.map((ev: Event) => {
                            if (ev.user_action) {
                                if (ev.user_action.action_type === 'resume') {
                                    return (
                                        <ResumeEvent key={ev.user_action?.id} event={ev}/>
                                    );
                                }
                                if (
                                    ev.user_action.action_type === 'utterance'
                                    && ev.user_action.payload.text
                                ) {
                                    return (
                                        <UserMessageEvent key={ev.user_action.id} event={ev}/>
                                    );
                                }
                            }
                            if (ev.agent_action) {
                                if (
                                    ev.agent_action.action_type === 'utterance'
                                    && ev.agent_action.payload.textual.text
                                ) {
                                    return (
                                        <AgentMessage key={ev.agent_action.id} event={ev}/>
                                    );
                                }
                            }
                            return null;
                        })
                    }
                </Space>
            }
        </FeedbackModal>
    );
};

TranscriptModal.title = 'Transcript';