import {QueryClient, QueryFunction, useQuery} from "react-query";
import api from './axios';
import * as Sentry from '@sentry/browser';
import {AxiosError} from "axios";
import browserHistory from "./browserHistory";
import {QueryKey} from "react-query/types/core/types";
import {UseQueryOptions, UseQueryResult} from "react-query/types/react/types";
import {createSearchParams, useSearchParams} from "react-router-dom";
import {useMemo} from "react";
import {QueryFilter} from "../components/DataTable/Filters/types";
import keycloak from "./keycloak";
import appConfig from "./config"

export interface QueryParams {
    path: string
}

const defaultQueryFn: QueryFunction = async ({ queryKey: [url] }) => {
    if (typeof url === 'string') {
        const { data } = await api.get(url);
        return data;
    }
    throw new Error('Invalid QueryKey')
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: appConfig.QUERY_STALE_TIME,
            queryFn: defaultQueryFn,
            retry: ((failureCount, error) => {
                if((error as AxiosError).isAxiosError) {
                    const axiosError = (error as AxiosError)
                    const isClientError = !!axiosError.response?.status
                        && 400 <= axiosError.response.status && axiosError.response.status < 500
                    return !isClientError
                }
                return failureCount < 3
            }),
            onError: (error: AxiosError | unknown) => {
                Sentry.captureException(error);
                if((error as AxiosError).isAxiosError) {
                    const axiosError = (error as AxiosError);
                    if (axiosError.response?.status === 404) {
                        browserHistory.replace('/not-found');
                    }
                    if(axiosError.response?.status === 403) {
                        keycloak.logout()
                    }
                }
            },
            useErrorBoundary: (error: AxiosError | unknown) => {
                // @ts-ignore
                return error?.response?.status >= 500;
            }
        }
    }
})

interface Options<TQueryFnData, TError, TData, TQueryKey extends QueryKey> extends Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'> {
    defaultFilters?: { [key: string]: (string | number | string[] | number []) }
}

export const getParamKey = (key: string, filter: string): string | false => {
    if (key === filter) return key;
    // there is a dot between filter prefix and the actual content
    if (key.substring(0, filter.length) === filter) return key.substring(filter.length + 1);
    return false;
}

export const buildSearchParams = (searchParams: URLSearchParams, filters: (string | QueryFilter)[]): any => {
    let params: any = {};
    searchParams.forEach((value, key) => {
        filters.forEach((filter) => {
            const transformedKey = getParamKey(key, filter);
            if (transformedKey) {
                return params[transformedKey] = value;
            }
        })
    })
    return params;
}

// This method passes a subset of the search params to the query. Search param keys are passed in searchParamFilters prop
// If you've passed the whole search param key, it will return it as is but if it is just the prefix of multiple search
// params keys, it will remove it that part of the key (e.g. `courses.date_published__max` with a filter 'courses' will
// result in `date_published__max`
export function useQuerySearchParams<TQueryFnData = unknown, TError = AxiosError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queryKey: TQueryKey, searchParamFilters: (string | QueryFilter)[], options?: Options<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
    const [searchParams] = useSearchParams();
    const searchParamsToApply = useMemo(() => {
        let params: any = buildSearchParams(searchParams, searchParamFilters);
        if (options?.defaultFilters) {
            Object.keys(options.defaultFilters).forEach(key => {
                params[key] = options.defaultFilters?.[key]
            })
        }
        return createSearchParams(params);
    }, [searchParams, searchParamFilters, options?.defaultFilters]);
    // @ts-ignore
    return useQuery<TQueryFnData, TError, TData, TQueryKey>(`${queryKey}?${searchParamsToApply}`, options);
}