import {FC, ReactElement} from "react";
import {FormInstance} from "antd";

export interface Filter<FilterConfigType> extends FilterProps<FilterConfigType> {
    component: FilterFC<FilterConfigType>,
}

export interface FilterProps<FilterConfigType> {
    attribute: string,
    title: string | ReactElement,
    config: FilterConfigType,
}

export interface FilterFC<FilterConfigType> extends FC<FilterProps<FilterConfigType> & {form: FormInstance}> {
    formatter: (value: any) => any;
}

export interface FilterableProps {
    filterPrefix: string
}

export enum QueryFilter {
    COHORTS = 'cohorts',
    COURSES = 'courses',
    PRIMARY_ATTRIBUTES = 'primary_attributes',
    NON_PRIMARY_ATTRIBUTES = 'non_primary_attributes'
}

export const allSearchParamFilters = [
    QueryFilter.NON_PRIMARY_ATTRIBUTES,
    QueryFilter.PRIMARY_ATTRIBUTES,
    QueryFilter.COURSES,
    QueryFilter.COHORTS
];