import React, { FC, ReactElement } from 'react';
import {GUTTER} from "../../../constants";
import {Space, Typography} from "antd";

const {Title} = Typography;

interface Props {
    title?: string,
    icon?: FC<any>,
}

export const PageSubHeader: FC<Props> = ({title, icon: Icon}): ReactElement => {
    return (
        <Space align='center' style={{marginTop: GUTTER[0] * 2}}>
            {Icon && <Icon style={{fontSize: 38, marginTop: -10}}/>}
            <Title level={1} style={{fontWeight: 400}}>{title}</Title>
        </Space>
    );
};