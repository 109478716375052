import React, {FC, Fragment, ReactElement, useMemo} from 'react';
import {Histogram} from "@ant-design/plots";
import {Card, Empty, Spin, Typography} from "antd";

const {Title} = Typography;

interface Props {
    data?: number[],
    title: string,
    unit?: string,
    isLoading?: boolean,
    label: string,
    range?: [number, number]
}

export const GlitchyHistogramChartCard: FC<Props> = ({title, data = [], unit, isLoading, label, range = [0, 100]}): ReactElement => {
    // It's glitchy because the graph repaints on every parent state update
    const averageValue = useMemo(() => {
        let count = 0;
        let score = 0;
        data?.forEach(value => {
            count++;
            score += value;
        });
        if (count > 0 ) return Math.floor(score / count);
        return 0;
    }, [data]);

    return (
        <Card title={title} className='chart-card'>
            {data && data.length > 0 ? <Fragment>
                <Title level={3}>Avg. {averageValue}{unit}</Title>
                <Histogram
                    binField='value'
                    binWidth={10}
                    tooltip={{
                        showMarkers: false,
                        position: 'top',
                    }}
                    interactions={[{
                        type: 'element-highlight',
                    }]}
                    // Hack: Make sure values are inside max and min of range
                    data={data.map((value) => ({value: Math.min(value, range[1] - 0.01)}))}
                    meta={{
                        range: {
                            min: range[0],
                            tickInterval: 10,
                            max: range[1],
                            // Add units
                            formatter: (value) => {
                                if (!unit) return value;
                                if (Array.isArray(value)) {
                                    return value.map(v => `${v}${unit}`);
                                }
                                return `${value}${unit}`
                            },
                        },
                        count: {
                            alias: label
                        }
                    }}
                />
            </Fragment> : isLoading ? <Spin/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card>
    );
};

export const HistogramChartCard = React.memo(GlitchyHistogramChartCard);