import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row, Space} from "antd";
import {
    MissedOrdersDataTableCard
} from "../components/Investigation/MissedOrdersDataTableCard";
import {
    UnnecessaryOrdersDataTableCard
} from "../components/Investigation/UnnecessaryOrdersDataTableCard";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";

export const InvestigationsTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
            <Col xl={6} xs={24}>
                <Space direction='vertical' size='middle' style={{width: '100%'}}>
                    <AverageScoreCard/>
                    <AverageTimeSpentCard/>
                </Space>
            </Col>
            <Col xl={10} xs={24}>
                <MissedOrdersDataTableCard/>
            </Col>
            <Col xl={8} xs={24}>
                <UnnecessaryOrdersDataTableCard/>
            </Col>
        </Row>
    );
};