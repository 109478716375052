import React, {FC, Fragment, ReactElement} from 'react';
import {useTopFilterSelectionListener} from "../../hooks/TopFilterSelectionListener";
import {QueryFilter} from "../../components/DataTable/Filters/types";
import {useParams} from "react-router-dom";
import {PageHeader} from "../../components/PageHeader";
import {
    BookOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useQuerySearchParams} from "../../config/queryClient";
import {Cohort} from "../../types";
import {SpinContainer} from "../../components/SpinContainer";
import {GUTTER} from "../../constants";
import {Col, Row} from "antd";
import {IconCard} from "../../components/IconCard";
import {CourseScoreHistogramCard} from "../Courses/components/CourseScoreHistogramCard";
import {StudentsDataTableCard} from "./components/StudentsDataTableCard";
import {formatMinutesDuration} from "../../helpers";
import {FilterPrefixProvider} from "../../components/DataTable/Filters/FilterPrefixProvider";
import {StudentEngagementLineChartCard} from "../Overview/components/StudentEngagementLineChartCard";
import {useLabels} from "../../hooks/Labels";

export const CohortPage: FC = (): ReactElement => {
    const {id} = useParams();
    const {t} = useLabels();
    useTopFilterSelectionListener(QueryFilter.COHORTS, id);
    const {data, isLoading} = useQuerySearchParams<Cohort>(
        `/reports/cohorts/${id}/`,
        [QueryFilter.COURSES]
    );

    if (isLoading) {
        return <SpinContainer/>
    }

    return (
        <Fragment>
            <PageHeader title={data?.name} icon={UsergroupAddOutlined}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={6}>
                    <IconCard
                        title={t('student_pl')}
                        isLoading={isLoading}
                        value={data?.total_students}
                        icon={<UserOutlined />}
                    />
                </Col>
                <Col xs={24} xl={6}>
                    <IconCard
                        title={`${t('course_pl')} available`}
                        isLoading={isLoading}
                        value={data?.total_courses}
                        icon={<BookOutlined />}
                    />
                </Col>
                <Col xs={24} xl={6}>
                    <IconCard
                        title={`${t('course')} completion`}
                        isLoading={isLoading}
                        value={data?.course_completion || 0}
                        unit='%'
                        icon={<CheckCircleOutlined />}
                        tooltip={`${t('course')} completion is based on all the ${t('course_pl', false)} available to the ${t('student_pl', false)} in this ${t('cohort', false)}`}
                    />
                </Col>
                <Col xs={24} xl={6}>
                    <IconCard
                        title='Avg. Time Spent'
                        isLoading={isLoading}
                        value={data?.average_time_spent ? formatMinutesDuration(data.average_time_spent) : 'N/A'}
                        icon={<ClockCircleOutlined />}
                        tooltip='Average time spent is based on the total duration of a consultation activity'
                    />
                </Col>
            </Row>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <Col xs={24} xl={12}>
                    <CourseScoreHistogramCard
                        defaultFilters={{cohorts: id}}
                        searchParamFilters={[QueryFilter.COURSES]}
                    />
                </Col>
                <Col xl={12} xs={24}>
                    <FilterPrefixProvider prefix='engagement'>
                        <StudentEngagementLineChartCard defaultFilters={{cohorts: id!}}/>
                    </FilterPrefixProvider>
                </Col>
            </Row>
            <Row gutter={GUTTER} style={{marginTop: GUTTER[0]}}>
                <StudentsDataTableCard/>
            </Row>
        </Fragment>
    );
};