import React, {useEffect} from "react";
import "./App.less";
import {Layout} from "./layout/Layout";
import {useKeycloak} from "@react-keycloak/web";
import {SpinContainer} from "./components/SpinContainer";
import {queryClient} from "./config/queryClient";
import browserHistory from "./config/browserHistory";
import {QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
// Check https://github.com/remix-run/react-router/pull/7586
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {ErrorBoundary} from "@sentry/react";
import {ServerErrorPage} from "./pages/ServerErrorPage";
import {OrganisationInformationProvider} from "./layout/OrganisationInformationProvider";

function App() {
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (initialized && !keycloak.authenticated) {
            keycloak.login().catch(() => window.location.reload());
        }
    }, [initialized, keycloak]);

    if (!initialized) {
        return <SpinContainer screenSize/>;
    }

    if (!keycloak.authenticated) {
        return null;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <HistoryRouter history={browserHistory}>
                <ErrorBoundary fallback={(props) => <ServerErrorPage {...props}/>}>
                    <OrganisationInformationProvider>
                        <Layout/>
                    </OrganisationInformationProvider>
                </ErrorBoundary>
            </HistoryRouter>
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
        </QueryClientProvider>
    );
}

export default App;
