import {FC, ReactElement} from 'react';
import {DataTableCard} from "./DataTable/DataTableCard";
import {Link} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {Space, Tag, Typography} from "antd";
import {Activity, Model} from "../types";
import {ActivityTypeNames} from "../constants";
import {SearchField} from "./DataTable/SearchField";
import {ExportButton} from "./DataTable/ExportButton";
import {FilterButton} from "./DataTable/FilterButton";
import {Filter} from "./DataTable/Filters/types";
import {NumericFilter} from "./DataTable/Filters/NumericFilter";
import {MultipleSelectQueryFilter} from "./DataTable/Filters/MultipleSelectQueryFilter";
import {MultipleSelectFilter} from "./DataTable/Filters/MultipleSelectFilter";
import {DataTableProps} from "./DataTable/DataTable";
import {useLabels} from "../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const {Text} = Typography;

const avgTimeOutOfLimits = (avg: number, estimated: number) => Math.abs(estimated - avg) >= 0.2 * estimated

export const activityFilters: Filter<any>[] = [
    {
        attribute: 'average_score',
        title: 'Average Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'estimated_time_minutes',
        title: 'Duration',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: 'm',
        },
    },
    {
        attribute: 'student_attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'primary_attributes',
        title: 'Primary Attributes',
        component: MultipleSelectQueryFilter,
        config: {
            query: 'management/activity-attributes?is_primary=true',
            labelKey: 'value',
            valueKey: 'id'
        }
    },
    {
        attribute: 'type',
        title: 'Type',
        component: MultipleSelectFilter,
        config: {
            options: Object.keys(ActivityTypeNames)
                .map((id: any) =>
                    ({name: ActivityTypeNames[id], id: parseInt(id)})
                ),
            labelKey: 'name',
            valueKey: 'id'
        }
    }
];

export const activityColumns: ColumnsType<Activity> = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (text, record) => <Link to={`/courses/activities/${record.id.toString()}`}>{text}</Link>
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        render: (text, record) => <Text>
            {
                record.renderers.length > 0 ?
                    record.renderers.map(renderer => renderer.name).join(', ') :
                    ActivityTypeNames[record.type]
            }
        </Text>
    },
    {
        title: 'Primary Attribute',
        dataIndex: 'primary_attribute',
        key: 'primary_attribute',
        render: (attribute) => attribute && <Tag>{attribute.value}</Tag>
    },
    {
        title: 'Duration',
        responsive: ['lg'],
        children: [
            {
                title: 'Est.',
                dataIndex: 'estimated_time_minutes',
                key: 'estimated_time_minutes',
                sorter: true,
                render: (value, record) => <Text>{value}m</Text>
            },
            {
                title: 'Avg.',
                dataIndex: 'avg_completion_time_minutes',
                key: 'avg_completion_time_minutes',
                sorter: true,
                render: (value, record) =>
                    <Text type={record.students_attempted > 0 &&
                    avgTimeOutOfLimits(value, record.estimated_time_minutes) ? 'danger' : undefined}>
                        {Math.round(value)}m
                    </Text>
            }
        ]
    },
    {
        title: 'Avg. Score',
        dataIndex: 'average_score',
        key: 'average_score',
        sorter: true,
        render: text => isNumeric(text) ? `${text}%` : 'N/A'
    },
    {
        title: 'Students',
        dataIndex: 'students_attempted',
        sorter: true,
        key: 'students_attempted',
        responsive: ['lg'],
    },
]

interface Props<T extends Model> {
    defaultFilters?: DataTableProps<T>['defaultFilters'],
    searchParamFilters?: DataTableProps<T>['searchParamFilters'],
    columns?: DataTableProps<T>['columns'],
    filters?: Filter<any>[]
}

export const ActivitiesDataTableCard: FC<Props<Activity>> = ({defaultFilters, searchParamFilters, columns = activityColumns, filters = activityFilters}): ReactElement => {
    const {t} = useLabels();
    return (
        <DataTableCard
            title={<Space size='large'>
                <Text>{t('activity_pl')}</Text>
                <SearchField placeholder={`${t('activity')} name`}/>
            </Space>}
            extra={<Space>
                <ExportButton
                    url={`/reports/activities/`}
                    filenamePrefix={t('activity_pl', false)}
                    defaultFilters={{
                        exclude: 'renderers,type,primary_attribute',
                        ...defaultFilters
                    }}
                    searchParamFilters={searchParamFilters}
                />
                <FilterButton filters={filters}/>
            </Space>}
            dataTable={{
                columns,
                searchParamFilters,
                query: `/reports/activities/`,
                defaultFilters
            }}/>
    );
};