import React, { FC, ReactElement } from 'react';
import {Col, Row} from "antd";
import {GUTTER} from "../../../constants";
import {AnswerOptimalityPieChartCard} from "../components/DialogueChoises/AnswerOptimalityPieChartCard";
import {SectionPerformanceDataTableCard} from "../components/Generic/SectionPerformanceDataTableCard";

export const DialogueChoicesRow: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
            <Col xs={24} xl={12}>
                <AnswerOptimalityPieChartCard/>
            </Col>
            <Col xs={24} xl={12}>
                <SectionPerformanceDataTableCard type='dialogue-choices'/>
            </Col>
        </Row>
    );
};