import React, {FC, ReactElement, useState} from 'react';
import {Button, Card, Space, Typography} from "antd";
import {LineChartOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {RecentActivityAttemptsDataTable} from "./RecentActivityAttemptsDataTable";
import {FilterPrefixProvider} from "../../../components/DataTable/Filters/FilterPrefixProvider";
import {DateFilter} from "../../Overview/components/DateFilter";
import {useLabels} from "../../../hooks/Labels";
import { useEngagementLineChartProps} from "../../Overview/components/StudentEngagementLineChartCard";
import {LineChartCard} from "../../Overview/components/EngagementLineChart";

const {Text} = Typography;

interface Props {
    showChart: boolean;
    setShowChart:  React.Dispatch<React.SetStateAction<boolean>>
}

const CardTitle: FC<Props> = ({showChart, setShowChart}): ReactElement => {
    const {t} = useLabels();
    return (
    <div style={{width: '100%', flexDirection: 'column', display: 'flex', gap: 8}}>
        <Space style={{justifyContent: "space-between", width: '100%'}}>
            <Text>Recent {t('activity', false)} attempts</Text>
            <Button
                icon={<LineChartOutlined />}
                type={showChart ? 'primary' : 'default'}
                onClick={() => setShowChart((value: boolean) => !value)}
            >
                Time view
            </Button>
        </Space>
        {showChart && <div style={{alignSelf: 'end'}}><DateFilter property='created_at'/></div>}
    </div>
)}

const RecentAttemptChartCard: FC<Props> = ({showChart, setShowChart}) => {
    const {id} = useParams();
    const {isLoading, chartProps, chartData} = useEngagementLineChartProps({student: id!});

    return (
        <LineChartCard
            title={<CardTitle showChart={showChart} setShowChart={setShowChart}/>}
            isLoading={isLoading}
            data={chartData}
            {...chartProps}
        />
    )
}

export const RecentActivityAttemptsCard: FC = (): ReactElement => {
    const [showChart, setShowChart] = useState<boolean>(false);
    return (
        <FilterPrefixProvider prefix={showChart ? 'engagement' : 'recent-attempts'}>
            {showChart
                ? <RecentAttemptChartCard showChart={showChart} setShowChart={setShowChart}/>
                : <Card
                    title={<CardTitle showChart={showChart} setShowChart={setShowChart}/>}
                    className='data-table-card'
                >
                    <RecentActivityAttemptsDataTable/>
                </Card>
            }
        </FilterPrefixProvider>
    );
};