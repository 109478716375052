import React, {FC, ReactElement} from 'react';
import {Button, Checkbox, Form, FormInstance, Space, Typography} from "antd";
import {Filter} from "./types";

const {Text} = Typography;

interface Props {
    form: FormInstance,
    clearFilters: () => void,
    applyFilters: () => void,
    onFinish: (values: any) => void,
    initialValues: any
    filters: Filter<any>[]
}

export const FiltersDropdownContainer: FC<Props> = ({form, clearFilters, applyFilters, onFinish, initialValues,  filters}): ReactElement => {
    return (
        <div className='filter-dropdown'>
            <Space
                direction='horizontal'
                align='center'
                size='large'
                className='filter-dropdown-header'
            >
                <Button onClick={clearFilters}>Clear</Button>
                <Text>Filters</Text>
                <Button type='primary' onClick={applyFilters}>Apply</Button>
            </Space>

            <Form
                form={form}
                name='filters'
                onFinish={onFinish}
                initialValues={initialValues}
            >
                {filters.map(({component: Filter, ...filter}) =>
                    <div key={filter.attribute}>
                        <div style={{padding: 10}}>
                            <Form.Item
                                key={filter.attribute}
                                name={`${filter.attribute}_enabled`}
                                noStyle
                                valuePropName='checked'
                            >
                                <Checkbox>{filter.title}</Checkbox>
                            </Form.Item>
                        </div>
                        <Form.Item noStyle dependencies={[`${filter.attribute}_enabled`]}>
                            {
                                ({getFieldValue}) => getFieldValue(`${filter.attribute}_enabled`) &&
                                    <div className='filter-dropdown-container'><Filter {...filter} form={form}/></div>
                            }
                        </Form.Item>
                    </div>)}
            </Form>
        </div>
    );
};