import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {GUTTER} from "../../constants";
import {SearchField} from "../../components/DataTable/SearchField";
import {Space} from "antd";
import {ExportButton} from "../../components/DataTable/ExportButton";
import {Filter, QueryFilter} from "../../components/DataTable/Filters/types";
import {FilterButton} from "../../components/DataTable/FilterButton";
import {DataTable} from "../../components/DataTable/DataTable";
import {NumericFilter} from "../../components/DataTable/Filters/NumericFilter";
import {ColumnsType} from "antd/lib/table";
import {Cohort} from "../../types";
import {Link} from "react-router-dom";
import {useLabels} from "../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const filters: Filter<any>[] = [
    {
        attribute: 'total_students',
        title: 'Students',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'remaining_seats',
        title: 'Available Seats',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    // {
    //     attribute: 'average_score',
    //     title: 'Score',
    //     component: NumericFilter,
    //     config: {
    //         filters: ['exact', 'range', 'lt', 'gt']
    //     },
    // },
    {
        attribute: 'course_completion',
        title: 'Completion',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    }
];

const columns: ColumnsType<Cohort> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (text, record) => <Link to={record.id.toString()}>{text}</Link>
    },
    {
        title: 'Students',
        dataIndex: 'total_students',
        key: 'total_students',
        sorter: true,
    },
    {
        title: 'Available Seats',
        dataIndex: 'remaining_seats',
        key: 'remaining_seats',
        sorter: true,
    },
    // {
    //     title: 'Score',
    //     dataIndex: 'average_score',
    //     key: 'average_score',
    //     sorter: true,
    //     render: text => isNumeric(text) ? `${text}%` : 'N/A'
    // },
    {
        title: 'Completion',
        dataIndex: 'course_completion',
        key: 'course_completion',
        sorter: true,
        render: text => isNumeric(text) ? `${text}%` : 'N/A'
    },
    // {
    //     title: 'Avg. Time Spent',
    //     dataIndex: 'average_time_spent',
    //     key: 'average_time_spent',
    //     sorter: true,
    //     render: text => isNumeric(text) ? `${Math.round(text)}m` : 'N/A'
    // },
];

export const CohortsPage: FC = (): ReactElement => {
    const {t} = useLabels();
    return (
        <Fragment>
            <PageHeader title={t('cohort_pl')}/>
            <div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: GUTTER[0]}}>
                    <SearchField placeholder={`${t('cohort')} name`}/>
                    <Space>
                        <ExportButton
                            url='/reports/cohorts/'
                            filenamePrefix='cohorts'
                            defaultFilters={{exclude: 'invitation_code'}}
                            searchParamFilters={[QueryFilter.COURSES]}
                        />
                        <FilterButton filters={filters}/>
                    </Space>
                </div>
                <DataTable
                    columns={columns}
                    query='/reports/cohorts/'
                    searchParamFilters={[QueryFilter.COURSES]}
                />
            </div>
        </Fragment>
    );
};