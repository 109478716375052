import React, {FC, ReactElement, useMemo} from 'react';
import {DataTableProps} from "../../../DataTable/DataTable";
import {allSearchParamFilters} from "../../../DataTable/Filters/types";
import {DataTableCard} from "../../../DataTable/DataTableCard";
import {useParams} from "react-router-dom";
import {DATATABLE_VERTICAL_SCROLL} from "../../../../constants";

export interface StudentExerciseAttemptReportDataTableProps extends Omit<DataTableProps<any>, 'title'> {
    title: string
}

export const StudentExerciseAttemptReportDataTableCard: FC<StudentExerciseAttemptReportDataTableProps> = ({title, ...rest}): ReactElement => {
    const {activityId, attemptId} = useParams();
    const query = useMemo(() => {
        if (!attemptId) {
            return rest.query
        }
        if (rest.query.endsWith('/')) {
            return `${rest.query}${attemptId}/`
        }
        return `${rest.query}/${attemptId}/`

    }, [rest, attemptId]);
    return (
        <DataTableCard
            title={title}
            dataTable={{
                searchParamFilters: allSearchParamFilters,
                ...rest,
                query,
                defaultFilters: {activity: activityId || '', ...rest.defaultFilters},
                pagination: false,
                scroll: DATATABLE_VERTICAL_SCROLL
            }}/>
    )
}