import React, {FC, ReactElement, useMemo} from 'react';
import {useParams} from "react-router-dom";
import {Activity, ActivityType} from "../../../types";
import {ActivityTypeNames} from "../../../constants";
import {PieChartCard} from "../../../components/PieChartCard";
import {useQuery} from "react-query";

export const ContentBreakdownCard: FC = (): ReactElement => {
    const {id} = useParams();
    const {data, isLoading} = useQuery<Activity[]>(
        `/reports/activities/?courses=${id}`
    );
    // Aggregate activities and transform them - [{type: 'Reading', value: 2}...]
    const chartData = useMemo(() => {
        const types = data?.reduce<{[key in ActivityType]?: number}>((acc, activity: Activity) => {
            if (!acc[activity.type]) {
                acc[activity.type] = 0;
            }
            // @ts-ignore
            acc[activity.type]++;
            return acc;
        }, {}) || {};
        // @ts-ignore
        return Object.keys(types).map((type) => ({type: ActivityTypeNames[type], value: types[type]}))
    }, [data]);

    return (
        <PieChartCard
            title='Content Breakdown'
            angleField='value'
            colorField='type'
            isLoading={isLoading}
            data={chartData}
        />
    );
};