import React, {FC, ReactElement, useMemo} from 'react';
import {Card, Descriptions} from "antd";
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {StudentExerciseAttempt} from "../../../../types";
import {GUTTER} from "../../../../constants";
import {useLabels} from "../../../../hooks/Labels";

export const OrdersSummaryDataTableCard: FC = (): ReactElement => {
    const {t} = useLabels();
    const {attemptId} = useParams();
    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(
        `/reports/student-exercise-attempts/${attemptId}/`,
        [], {
            defaultFilters: {
                fields: 'id,feedback'
            }
        })
    const studentOrders = useMemo(() => {
        const orders = data?.feedback?.user_orders?.orders.reduce(
            (acc: number, order: any) => acc + order?.investigations.length, 0
        )
        return {
            total_orders: orders,
            total_cost: data?.feedback?.user_orders?.total_cost,
            total_time_estimated: data?.feedback?.user_orders?.total_time_estimated,
        }
    }, [data]);
    const optimalOrders = useMemo(() => {
        const orders = data?.feedback?.optimal_orders?.optimal_orders_list.length;
        return {
            total_orders: orders,
            total_cost: data?.feedback?.optimal_orders?.total_cost,
            total_time_estimated: data?.feedback?.optimal_orders?.total_time_estimated,
        }
    }, [data]);

    return (
        <Card title={t('summary_title')} className='data-table-card' loading={isLoading}>
            <Descriptions
                layout='vertical'
                style={{paddingLeft: GUTTER[0], paddingRight: GUTTER[0]}}
                column={{xs: 3}}
            >
                <Descriptions.Item> </Descriptions.Item>
                <Descriptions.Item>Ordered</Descriptions.Item>
                <Descriptions.Item>Optimal</Descriptions.Item>
                <Descriptions.Item>Order count</Descriptions.Item>
                <Descriptions.Item>{studentOrders.total_orders}</Descriptions.Item>
                <Descriptions.Item>{optimalOrders.total_orders}</Descriptions.Item>
                <Descriptions.Item>Spending</Descriptions.Item>
                <Descriptions.Item>{studentOrders.total_cost}</Descriptions.Item>
                <Descriptions.Item>{optimalOrders.total_cost}</Descriptions.Item>
                <Descriptions.Item>Time estimate</Descriptions.Item>
                <Descriptions.Item>{studentOrders.total_time_estimated}</Descriptions.Item>
                <Descriptions.Item>{optimalOrders.total_time_estimated}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};