import React, {FC, ReactElement} from 'react';
import {useParams} from "react-router-dom";
import {IconCardProps} from "../../../components/IconCard";
import {RankingCard} from "./RankingCard";

interface Props {
    defaultFilters?: { [key: string]: (string | number | string[] | number []) },
    tooltip?: IconCardProps['tooltip']
}

export const StudentRankingCard: FC<Props> = ({defaultFilters, tooltip}): ReactElement => {
    const {id} = useParams();
    return (
        <RankingCard
            url={`/reports/students/${id}/ranking`}
            defaultFilters={defaultFilters}
            tooltip={tooltip}
        />
    );
};