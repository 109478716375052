import React, {createContext, FC, ReactElement, useContext} from 'react';

const DEFAULT_PREFIX = 'f';
export const FilterContext = createContext<string>(DEFAULT_PREFIX);

interface Props {
    prefix: string
}

// Use that to define a filter prefix to a set of data table components (filter button, search field, export, etc.)
// You have to use it when you have more than one filter button component per page
export const FilterPrefixProvider: FC<Props> = ({children, prefix}): ReactElement => {
    return (
        <FilterContext.Provider value={prefix}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilterPrefix = (): string => useContext(FilterContext);