import React, {FC, Fragment, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {useParams} from "react-router-dom";
import {Activity, ActivityType} from "../../types";
import {useQuerySearchParams} from "../../config/queryClient";
import {SpinContainer} from "../../components/SpinContainer";
import {GUTTER} from "../../constants";
import {Col, Row} from "antd";
import {DescriptionCard} from "./components/DescriptionCard";
import {ClockCircleOutlined, FileOutlined} from "@ant-design/icons";
import {IconCard} from "../../components/IconCard";
import {ActivityAttemptsCard} from "./components/ActivityAttemptsCard";
import {Exercise} from "./Activities/Exercise";
import {Reading} from "./Activities/Reading";
import {QueryFilter} from "../../components/DataTable/Filters/types";
import {useTopFilterSelectionListener} from "../../hooks/TopFilterSelectionListener";
import {useActivitySubtitle} from "./hooks/ActivitySubtitle";
import {formatMinutesDuration} from "../../helpers";

const renderDetails = (activity: Activity) => {
    switch(activity.type) {
        case ActivityType.EXERCISE:
            return <Exercise activity={activity}/>
        // All static activities use the same UI at the moment
        case ActivityType.WRITING_ASSIGNMENT:
            // return <WritingAssignment activity={activity}/>
        case ActivityType.VIDEO:
            // return <Video activity={activity}/>
        case ActivityType.READING:
            return <Reading activity={activity}/>
    }
}

export const ActivityPage: FC = (): ReactElement => {
    const {activityId} = useParams();
    const {data: activity, isLoading} = useQuerySearchParams<Activity>(
        `/reports/activities/${activityId}/`,
        [QueryFilter.COHORTS]
    );
    useTopFilterSelectionListener(QueryFilter.COURSES, activity?.course.id);
    const subtitle = useActivitySubtitle(activity);

    if (isLoading) {
        return <SpinContainer/>
    }



    return (
        <Fragment>
            <PageHeader title={activity?.title} icon={FileOutlined} subtitle={subtitle}/>
            <Row gutter={GUTTER}>
                <Col xs={24} xl={12}>
                    <DescriptionCard html={activity?.description || 'N/A'}/>
                </Col>
                <Col xs={12} xl={6} style={{display: 'block'}}>
                    <IconCard
                        title='Time Est.'
                        value={activity?.estimated_time_minutes ? formatMinutesDuration(activity.estimated_time_minutes) : 'N/A'}
                        icon={<ClockCircleOutlined />}
                    />
                </Col>
                <Col xs={12} xl={6} style={{display: 'block'}}>
                    {activity && <ActivityAttemptsCard activity={activity}/>}
                </Col>
            </Row>
            {activity && renderDetails(activity)}
        </Fragment>
    );
};