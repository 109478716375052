import React, {ReactElement} from 'react';
import {Form, Select} from "antd";
import {useQuery} from "react-query";
import {FilterFC} from "./types";

const {Option} = Select;

interface Props {
    query: string,
    labelKey: string,
    valueKey: string,
}

export const MultipleSelectQueryFilter: FilterFC<Props> = ({attribute, config}): ReactElement => {
    const {data = [], isLoading} = useQuery<{[key: string]: string | number}[]>(config.query);
    return (
        <Form.Item name={attribute}>
            <Select
                loading={isLoading}
                mode='multiple'
                placeholder='Select...'
                maxTagCount='responsive'
            >
                {data.map((option) =>
                    <Option
                        key={option[config.valueKey]}
                        value={option[config.valueKey]}
                    >
                        {option[config.labelKey]}
                    </Option>
                )}
            </Select>
        </Form.Item>
    );
};

MultipleSelectQueryFilter.formatter = (values) => {
    if (typeof values === 'string') {
        return values.split(',').map(value => parseInt(value))
    }
    return values.join(',')
}