import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row, Space} from "antd";
import {
    SectionPerformanceDataTableCard
} from "../components/Generic/SectionPerformanceDataTableCard";
import {AnswerOptimalityPieChartCard} from "../components/DialogueChoises/AnswerOptimalityPieChartCard";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";

export const DialogueChoicesTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
            <Col xl={6} xs={24}>
                <Space direction='vertical' size='middle' style={{width: '100%'}}>
                    <AverageScoreCard/>
                    <AverageTimeSpentCard/>
                </Space>
            </Col>
            <Col xl={10} xs={24}>
                <AnswerOptimalityPieChartCard/>
            </Col>
            <Col xl={8} xs={24}>
                <SectionPerformanceDataTableCard type='dialogue-choices'/>
            </Col>
        </Row>
    );
};