import { FC, ReactElement } from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row} from "antd";
import {CommonlyMissedDataTableCard} from "../components/ConversationStory/CommonlyMissedDataTableCard";
import {SectionPerformanceDataTableCard} from "../components/Generic/SectionPerformanceDataTableCard";
import {useParams} from "react-router-dom";
import {
    AttemptConversationPerformanceDataTableCard
} from "../components/ConversationStory/AttemptConversationPerformanceDataTableCard";

export const ConversationStoryRow: FC = (): ReactElement => {
    const {attemptId} = useParams();
    return (
        <Row gutter={GUTTER}>
            <Col xs={24} xl={12}>
                <CommonlyMissedDataTableCard/>
            </Col>
            <Col xs={24} xl={12}>
                {attemptId ? <AttemptConversationPerformanceDataTableCard/> :
                    <SectionPerformanceDataTableCard type='conversation'/>}
            </Col>
        </Row>
    );
};