import {ActivityType} from "./types";

export const ActivityTypeNames: {[key: number]: string} = {
    [ActivityType.EXERCISE]: 'Exercise',
    [ActivityType.READING]: 'Reading',
    [ActivityType.VIDEO]: 'Video',
    [ActivityType.WRITING_ASSIGNMENT]: 'Writing Assignment',
}

export const GUTTER:[number, number] = [16,16];
export const DATE_FORMAT = 'D MMM YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;
export const SHORT_DATETIME_FORMAT = 'DD MMM - HH:mm';

export const DATATABLE_VERTICAL_SCROLL = {y: 350};