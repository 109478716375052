import React, {ReactElement} from 'react';
import {FeedbackModal, FeedbackModalFC} from "../Generic/FeedbackModal";
import {useParams} from "react-router-dom";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {StudentExerciseAttempt} from "../../../../types";
import {SpinContainer} from "../../../SpinContainer";
import {Divider, List, Typography} from "antd";
import {GUTTER} from "../../../../constants";

const {Text} = Typography;

interface Order {
    id: string,
    investigations: Investigation[]
}

interface Investigation {
    id: string,
    cost: string,
    name: string,
    result: string,
    time_estimated: string
}

export const OrdersModal: FeedbackModalFC<any> = (): ReactElement => {
    const {attemptId} = useParams();
    const {data, isLoading} = useQuerySearchParams<StudentExerciseAttempt>(`/reports/student-exercise-attempts/${attemptId}/`, [], {
        defaultFilters: {
            fields: 'id,feedback'
        }
    })
    return (
        <FeedbackModal title={OrdersModal.title}>
            {isLoading ? <SpinContainer/> : data?.feedback?.user_orders.orders.map((order:Order, index: number) =>
                <div key={order.id} style={{paddingLeft: GUTTER[0], paddingRight: GUTTER[0]}}>
                    <Divider orientation="left">Order {index + 1}</Divider>
                    <List dataSource={order.investigations} renderItem={item => (
                        <List.Item>
                            <Text>{item.name}</Text>
                            <Text>{item.cost}</Text>
                            <Text>{item.time_estimated}</Text>
                        </List.Item>
                    )}/>
                </div>
            )}
        </FeedbackModal>
    );
};

OrdersModal.title = 'Student Orders';