import React, {FC, ReactElement} from 'react';
import {Card} from "antd";
import ShowMoreText from "react-show-more-text";

interface Props {
    html: string
}

export const DescriptionCard: FC<Props> = ({html}): ReactElement => (
    <Card title='Description'>
        <ShowMoreText
            lines={1}
            more="Show more"
            less="Show less"
            expanded={false}
            truncatedEndingComponent='... '
        >
            <div dangerouslySetInnerHTML={{__html: html}}/>
        </ShowMoreText>
    </Card>
);