import React, { ReactElement } from 'react';
import {Form, Select} from "antd";
import {FilterFC} from "./types";

const {Option} = Select;

interface Props {
    options: any[],
    labelKey: string,
    valueKey: string,
}

export const MultipleSelectFilter: FilterFC<Props> = ({config, attribute}): ReactElement => {
    return (
        <Form.Item name={attribute}>
            <Select
                mode='multiple'
                placeholder='Select...'
                maxTagCount='responsive'
            >
                {config.options.map((option) =>
                    <Option
                        key={option[config.valueKey]}
                        value={option[config.valueKey]}
                    >
                        {option[config.labelKey]}
                    </Option>
                )}
            </Select>
        </Form.Item>
    );
};

MultipleSelectFilter.formatter = (values) => {
    if (typeof values === 'string') {
        return values.split(',').map(value => parseInt(value))
    }
    return values.join(',')
}