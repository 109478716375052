import React, { FC, ReactElement } from 'react';
import {useQuerySearchParams} from "../../../config/queryClient";
import {StudentRanking} from "../../../types";
import {QueryFilter} from "../../../components/DataTable/Filters/types";
import * as Sentry from "@sentry/browser";
import {IconCard, IconCardProps} from "../../../components/IconCard";
import {StarOutlined} from "@ant-design/icons";

interface Props {
    url: string
    defaultFilters?: { [key: string]: (string | number | string[] | number []) },
    tooltip?: IconCardProps['tooltip']
}

export const RankingCard: FC<Props> = ({url, defaultFilters, tooltip}): ReactElement => {
    const {data, isLoading} = useQuerySearchParams<StudentRanking>(
        url,
        [QueryFilter.COHORTS, QueryFilter.COURSES],
        {
            defaultFilters,
            onError: (error) => {
                // Query might return 404 depending on the filters applied, and we want to show N/A in that case
                if (error?.response?.status === 404) {
                    return;
                }
                Sentry.captureException(error);
            }
        });
    return (
        <IconCard
            title='Ranking'
            isLoading={isLoading}
            value={data?.rank}
            icon={<StarOutlined/>}
            description={data?.total ? `out of ${data?.total}` : undefined}
            tooltip={tooltip}
        />
    );
};