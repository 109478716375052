import React, {FC, ReactElement, useEffect, useMemo, useState} from 'react';
import {Input} from "antd";
import {useSearchParams} from "react-router-dom";
import {SearchProps} from "antd/lib/input";
import {useFilterPrefix} from "./Filters/FilterPrefixProvider";

const {Search} = Input;

interface Props extends SearchProps {
    queryParam?: string,
    encode?: (value: string) => string;
    decode?: (value: string) => string;
}

export const SearchField: FC<Props> = ({queryParam = 'search', encode = (value) => value, decode = (value) => value, ...rest}): ReactElement => {
    const filterPrefix = useFilterPrefix();
    const [value, setValue] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const fieldName = useMemo(() => `${filterPrefix}.${queryParam}`, [filterPrefix, queryParam]);

    useEffect(() => {
        if (searchParams.has(fieldName)) {
            // @ts-ignore
            setValue(decode(searchParams.get(fieldName)))
        } else {
            setValue('');
        }
    }, [searchParams, fieldName]);

    return (
        <Search {...rest}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{maxWidth: 200}}
                onSearch={(value) => {
                    if (value) {
                        searchParams.delete(`${filterPrefix}.page`);
                        searchParams.set(fieldName, encode(value));
                    } else {
                        searchParams.delete(fieldName);
                    }
                    setSearchParams(searchParams);
                }}
        />
    );
};