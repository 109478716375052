import {FC, Fragment, ReactElement} from 'react';
import {Space, Tag} from "antd";
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {Link} from "react-router-dom";
import {ExportButton} from "../../components/DataTable/ExportButton";
import {PageHeader} from "../../components/PageHeader";
import {ActivityAttribute, Course} from "../../types";
import {SearchField} from "../../components/DataTable/SearchField";
import {DataTable} from "../../components/DataTable/DataTable";
import {FilterButton} from "../../components/DataTable/FilterButton";
import {Filter, QueryFilter} from "../../components/DataTable/Filters/types";
import {NumericFilter} from "../../components/DataTable/Filters/NumericFilter";
import {DateFilter} from "../../components/DataTable/Filters/DateFilter";
import {MultipleSelectQueryFilter} from "../../components/DataTable/Filters/MultipleSelectQueryFilter";
import {DATE_FORMAT, GUTTER} from "../../constants";
import {useLabels} from "../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const filters: Filter<any>[] = [
    {
        attribute: 'average_score',
        title: 'Average Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'enrollment_rate_percentage',
        title: 'Enrollment Rate',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%',
        },
    },
    {
        attribute: 'total_attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
        },
    },
    {
        attribute: 'date_published',
        title: 'Date Published',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt', 'range']
        },
    },
    {
        attribute: 'primary_attributes',
        title: 'Primary Attributes',
        component: MultipleSelectQueryFilter,
        config: {
            query: 'management/activity-attributes?is_primary=true',
            labelKey: 'value',
            valueKey: 'id'
        }
    }
];

const columns: ColumnsType<Course> = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (text, record) => <Link to={record.id.toString()}>{text}</Link>
    },
    {
        title: 'Attributes',
        dataIndex: 'primary_attributes',
        key: 'primary_attributes',
        responsive: ['lg'],
        render: (attributes) => (
            <Fragment>
                {
                    attributes
                    .map((attribute: ActivityAttribute) => attribute.value)
                    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i) // select distinct
                    .map((attribute: string) => <Tag key={attribute}>{attribute}</Tag>)
                }
            </Fragment>
        )
    },
    {
        title: 'Date published',
        dataIndex: 'date_published',
        key: 'date_published',
        responsive: ['lg'],
        sorter: true,
        render: text => text ? moment(text).format(DATE_FORMAT) : 'N/A'
    },
    {
        title: 'Avg. Score',
        dataIndex: 'average_score',
        key: 'average_score',
        sorter: true,
        render: text => isNumeric(text) ? `${text}%` : 'N/A'
    },
    {
        title: 'Enrollment',
        dataIndex: 'enrollment_rate_percentage',
        key: 'enrollment_rate_percentage',
        sorter: true,
        render: text => `${text}%`
    },
    {
        title: 'Attempts',
        dataIndex: 'total_attempts',
        sorter: true,
        key: 'total_attempts'
    },
];

export const CoursesPage: FC = (): ReactElement => {
    const {t} = useLabels();
    return (
        <Fragment>
            <PageHeader title={t('course_pl')}/>
            <div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: GUTTER[0]}}>
                    <SearchField placeholder='Course name'/>
                    <Space>
                        <ExportButton
                            url='/reports/courses/'
                            filenamePrefix='courses'
                            defaultFilters={{exclude: 'primary_attributes'}}
                            searchParamFilters={[QueryFilter.COHORTS]}
                        />
                        <FilterButton filters={filters}/>
                    </Space>
                </div>
                <DataTable
                    columns={columns}
                    query='/reports/courses/'
                    searchParamFilters={[QueryFilter.COHORTS]}
                />
            </div>
        </Fragment>
    );
};