import React, {FC, ReactElement, useMemo} from 'react';
import {StudentCourse} from "../../../types";
import {useQuerySearchParams} from "../../../config/queryClient";
import {QueryFilter} from "../../../components/DataTable/Filters/types";
import {HistogramChartCard} from "../../../components/HistogramChartCard";
import {useLabels} from "../../../hooks/Labels";

interface Props {
    searchParamFilters?: QueryFilter[],
    defaultFilters?: any
}

export const CourseScoreHistogramCard: FC<Props> = ({searchParamFilters = [], defaultFilters = {}}): ReactElement => {
    const {t} = useLabels();
    const {data, isLoading} = useQuerySearchParams<StudentCourse[]>('/reports/student-courses/',
        searchParamFilters, {
        defaultFilters: {
            ...defaultFilters,
            fields: 'id,score'
        }
    })

    const chartData = useMemo((): number[] | undefined =>
        // @ts-ignore
        data?.map(item => item.score).filter(score => score != null), [data]
    );

    return (
        <HistogramChartCard
            title='Score'
            label={t('student_pl')}
            data={chartData}
            unit='%'
            isLoading={isLoading}
        />
    );
};