import React, {FC, ReactElement, useMemo} from 'react';
import {Space, Typography} from "antd";
import {SearchField} from "../../../components/DataTable/SearchField";
import {ExportButton} from "../../../components/DataTable/ExportButton";
import {Filter, QueryFilter} from "../../../components/DataTable/Filters/types";
import {FilterButton} from "../../../components/DataTable/FilterButton";
import {DataTableCard} from "../../../components/DataTable/DataTableCard";
import {ColumnsType} from "antd/lib/table";
import {Student} from "../../../types";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {NumericFilter} from "../../../components/DataTable/Filters/NumericFilter";
import {DATE_FORMAT} from "../../../constants";
import {DateFilter} from "../../../components/DataTable/Filters/DateFilter";
import {useLabels} from "../../../hooks/Labels";
import isNumeric from "antd/lib/_util/isNumeric";

const {Text} = Typography;

const filters: Filter<any>[] = [
    {
        attribute: 'average_score',
        title: 'Score',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%'
        },
    },
    {
        attribute: 'course_completion',
        title: 'Completion',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt'],
            unit: '%'
        },
    },
    {
        attribute: 'attempts',
        title: 'Attempts',
        component: NumericFilter,
        config: {
            filters: ['exact', 'range', 'lt', 'gt']
        },
    },
    {
        attribute: 'latest_attempt',
        title: 'Last Attempted',
        component: DateFilter,
        config: {
            filters: ['lt', 'gt', 'range']
        },
    },
];

const columns: ColumnsType<Student> = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render: (text, record) => <Link to={`/students/${record.id}`}>{text}</Link>
    },
    {
        title: 'Score',
        dataIndex: 'average_score',
        key: 'average_score',
        render: (value) => isNumeric(value) ? `${value}%` : 'N/A',
        sorter: true
    },
    {
        title: 'Completion',
        dataIndex: 'course_completion',
        key: 'course_completion',
        render: (value) => `${value}%`,
        sorter: true
    },
    {
        title: 'Attempts',
        dataIndex: 'attempts',
        key: 'attempts',
        sorter: true
    },

    {
        title: 'Most recent activity',
        dataIndex: 'latest_attempt',
        key: 'latest_attempt',
        sorter: true,
        render: (value) => value ? moment(value).format(DATE_FORMAT):
            '-'
    }
]

export const StudentsDataTableCard: FC = (): ReactElement => {
    const {id} = useParams();
    const {t} = useLabels();

    const defaultFilters = useMemo(() => ({
        cohorts: id || ''
    }), [id]);

    return (
        <DataTableCard
            title={<Space size='large'>
                <Text>{t('student_pl')}</Text>
                <SearchField placeholder={`${t('student')} name or email`}/>
            </Space>}
            extra={<Space>
                <ExportButton
                    url='/reports/students/'
                    filenamePrefix='students'
                    defaultFilters={defaultFilters}
                    searchParamFilters={[QueryFilter.COURSES]}
                />
                <FilterButton filters={filters}/>
            </Space>}
            dataTable={{
                searchParamFilters: [QueryFilter.COURSES],
                defaultFilters,
                columns,
                query: `/reports/students/`
            }}/>
    );
};