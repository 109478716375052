import React, {FC, ReactElement, useMemo} from 'react';
import {ColumnChartCard} from "../../../components/ColumnChartCard";
import {useQuerySearchParams} from "../../../config/queryClient";
import {ActivityAttributeMeta, ActivityAttributeReport} from "../../../types";
import {QueryFilter} from "../../../components/DataTable/Filters/types";

interface Props {
    attributeMeta?: ActivityAttributeMeta
}

const chartProps = {
    xField: 'value',
    yField: 'avg_score',
    seriesField: 'value',
    yAxis: {
        label: {
            formatter: (text: any) => `${text}%`
        },
    }
}

export const AttributePerformanceColumnChartCard: FC<Props> = ({attributeMeta}): ReactElement => {
    const {data = [], isLoading} = useQuerySearchParams<ActivityAttributeReport[]>(
        `/reports/activity-attributes/`,
        [QueryFilter.COHORTS, QueryFilter.COURSES],
        {defaultFilters: {meta: attributeMeta?.id || ''}}
    );
    const chartData = useMemo(() =>
        data.map(attribute => ({...attribute, avg_score: attribute.avg_score ?? 0})), [data]);
    return (
        <ColumnChartCard
            title={`Avg. ${attributeMeta?.name || ''} Performance`}
            isLoading={isLoading}
            data={chartData}
            {...chartProps}
        />
    );
};