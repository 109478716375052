import React, { FC, ReactElement } from 'react';
import {QueryFilter} from "../../../components/DataTable/Filters/types";
import {DataTable} from "../../../components/DataTable/DataTable";
import {Link, useParams} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {StudentExerciseAttempt} from "../../../types";
import moment from "moment";
import {DATATABLE_VERTICAL_SCROLL, DATETIME_FORMAT} from "../../../constants";
import isNumeric from "antd/lib/_util/isNumeric";


export const renderActivityScore = (value: any, record: StudentExerciseAttempt ) => {
    if(!record.completed_datetime) {
        return "In progress"
    }
    if(isNumeric(value)) {
        return `${value}%`
    }
    return record.passed ? 'Passed' : 'Not passed'
}
const columns: ColumnsType<StudentExerciseAttempt> = [
    {
        title: 'Activity',
        dataIndex: ['activity', 'title'],
        key: 'activity.title',
        sorter: true,
        render: (text, record) => {
            return record.completed_datetime
                ? <Link to={`activity/${record.student_activity}/${record.id}`}>{text}</Link>
                : text
        }
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        sorter: true,
        render: renderActivityScore
    },
    {
        title: 'Date Started',
        dataIndex: 'started_datetime',
        key: 'started_datetime',
        sorter: true,
        render: (value) => moment(value).format(DATETIME_FORMAT)
    }
]

export const RecentActivityAttemptsDataTable: FC = (): ReactElement => {
    const {id} = useParams();
    return (
        <DataTable
            columns={columns}
            defaultFilters={{student: id || '', fields: 'id,activity,score,started_datetime,completed_datetime,passed,student_activity'}}
            searchParamFilters={[QueryFilter.COURSES, QueryFilter.COHORTS]}
            query='/reports/student-exercise-attempts/'
            pagination={false}
            scroll={DATATABLE_VERTICAL_SCROLL}
        />
    );
};