import React, {FC, ReactElement} from 'react';
import {GUTTER} from "../../../constants";
import {Col, Row, Space} from "antd";
import {
    SectionPerformanceDataTableCard
} from "../components/Generic/SectionPerformanceDataTableCard";
import {AverageScoreCard} from "../components/Generic/AverageScoreCard";
import {AverageTimeSpentCard} from "../components/Generic/AverageTimeSpentCard";

export const TranscriptFeedbackTab: FC = (): ReactElement => {
    return (
        <Row gutter={GUTTER}>
                <Col xl={6} xs={24}>
                    <Space direction='vertical' size='middle' style={{width: '100%'}}>
                        <AverageScoreCard/>
                        <AverageTimeSpentCard/>
                    </Space>
                </Col>
                <Col xl={18} xs={24}>
                    {/* GPT feedback has a similar structure to conversation w.r.t. subsections */}
                    <SectionPerformanceDataTableCard type='conversation'/>
                </Col>
        </Row>
    );
};