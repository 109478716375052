import React, {FC, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {FilterOutlined} from "@ant-design/icons";
import {Button, Dropdown, Form} from "antd";
import {useSearchParams} from "react-router-dom";
import {FiltersDropdownContainer} from "./Filters/FiltersDropdownContainer";
import {Filter} from "./Filters/types";
import './Filters/styles.css';
import {getParamKey} from "../../config/queryClient";
import {useFilterPrefix} from "./Filters/FilterPrefixProvider";

interface Props {
    filters: Filter<any>[],
}

// This component accepts an array of filter configs which is dynamically built and rendered in a dropdown.
// The state of all filters applied is stored in search params. Each filter can have multiple form fields, whose name is
// search param name and value - search param value. All form field names per filter should have the attribute name
// as prefix if you want them included in the search params!
// Each filter has the following default form fields -
// 1. attribute_enabled - boolean to toggle the filter, initial value is true if there are any search params that
// have the attribute name as prefix.
// 2. attribute_options - If a filter has more than one form field, or you want to add some kind of modifier
// to any of the fields, you can include an option field, which initial value is the value out of the following:
// attribute__value__modifier (e.g. published_date__range__max).
//
// Backend filters should be of the following format - attribute__lookup_exp and if they accept any kind of additional
// modifiers - attribute__lookup_exp__modifier
export const FilterButton: FC<Props> = ({filters}): ReactElement => {
    const filterPrefix = useFilterPrefix();
    const [searchParams, setSearchParams] = useSearchParams();
    const [enabledFilterCounter, setEnabledFilterCounter] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    // Build initial form values based on search params
    const buildValuesFromSearchParams = useCallback(() => {
        let values:any = {};
        filters.forEach(filter => {
            searchParams.forEach((value, key) => {
                const transformedKey = getParamKey(key, filterPrefix);
                if (transformedKey && transformedKey.includes(filter.attribute)) {
                    values[transformedKey] = filter.component.formatter(value);
                    values[`${filter.attribute}_enabled`] = true; // Enable checkbox
                    values[`${filter.attribute}_option`] = transformedKey.split('__')[1]; // Set additional options field
                }
            })
            values[`${filter.attribute}_enabled`] = !!values[`${filter.attribute}_enabled`]; // Cast undefined to bool
            if (!values[`${filter.attribute}_option`]) { // Set a default option
                values[`${filter.attribute}_option`] = filter.config?.filters?.[0];
            }
        })
        return values;
    }, [searchParams, filters, filterPrefix]);

    const values = useMemo(() => buildValuesFromSearchParams(), [buildValuesFromSearchParams]);

    const deleteFilterSearchParams = useCallback(() => {
        let paramsToDelete: string[] = [];
        searchParams.forEach((value, key) => {
            filters.forEach(filter => {
                if (key.includes(`${filterPrefix}.${filter.attribute}`)) {
                    paramsToDelete.push(key);
                }
            })
        })
        paramsToDelete.forEach((key: string) => searchParams.delete(key))
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, filters, filterPrefix]);

    // Gets all the form fields and update query params with the needed filters
    const onFinish = useCallback((values: any) => {
        let searchParamFilters: any = [];
        filters.filter(filter => values[`${filter.attribute}_enabled`]) // Get enabled filters only
            .forEach(activeFilter => Object.keys(values).forEach(filter => {
                if (filter.includes(activeFilter.attribute) // Get all related form fields for that filter
                    && !filter.includes('_enabled')
                    && !filter.includes('_option')) {
                    const value = activeFilter.component.formatter(values[filter]);
                    if (value === undefined) return; // continue
                    // Save filter to be added to query params
                    searchParamFilters.push({name: `${filterPrefix}.${filter}`, value});
                }
            }))
        searchParamFilters.forEach((filter: any) => {
            searchParams.set(filter.name, filter.value)
        })
        searchParams.delete(`${filterPrefix}.page`);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, filters, filterPrefix]);

    const clearFilters = useCallback(() => {
        setOpen(false);
        deleteFilterSearchParams();
        form.setFieldsValue(buildValuesFromSearchParams()); // manually reset form
    }, [form, deleteFilterSearchParams, buildValuesFromSearchParams]);

    const applyFilters = useCallback(() => {
        setOpen(false);
        deleteFilterSearchParams();
        form.submit();
    }, [form, deleteFilterSearchParams]);

    // Count enabled filters
    useEffect(() => {
        const enabledFiltersCount = Object.keys(values)
            .filter(value => value.endsWith('_enabled'))
            .reduce((acc, property) => values[property] ? acc + 1 : acc, 0);
        setEnabledFilterCounter(enabledFiltersCount);
    }, [form, values])

    return (
        <Dropdown
            visible={open}
            overlay={<FiltersDropdownContainer
                form={form}
                clearFilters={clearFilters}
                applyFilters={applyFilters}
                onFinish={onFinish}
                initialValues={values}
                filters={filters}/>
            }
            trigger={['click']}
            onVisibleChange={setOpen}
            placement='bottomLeft'
        >
            <Button
                type={enabledFilterCounter > 0 ? 'primary' : 'default'}
                icon={<FilterOutlined/>}
                onClick={() => setOpen(open => !open)}
            >
                Filter {enabledFilterCounter ? `(${enabledFilterCounter})` : ''}
            </Button>
        </Dropdown>
    );
};