import React, { FC, ReactElement } from 'react';
import {useParams} from "react-router-dom";
import {RankingCard} from "./RankingCard";

interface Props {
    defaultFilters?: { [key: string]: (string | number | string[] | number []) },
}

export const AttemptRankingCard: FC<Props> = ({defaultFilters}): ReactElement => {
    const {attemptId} = useParams();
    return (
        <RankingCard
            url={`/reports/student-exercise-attempts/${attemptId}/ranking`}
            defaultFilters={defaultFilters}
            tooltip='Ranking of this attempt against all student attempts for this activity.'
        />
    );
};