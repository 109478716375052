import React, {FC, ReactElement} from 'react';
import {Grid, Table, TableProps} from "antd";
import {Model, PagedResponse} from "../../types";
import {useSearchParams} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {useQuerySearchParams} from "../../config/queryClient";
import {useFilterPrefix} from "./Filters/FilterPrefixProvider";
import {SorterResult} from "antd/lib/table/interface";
import {QueryFilter} from "./Filters/types";
import {PAGE_SIZE, usePagination} from "./Pagination";

const {useBreakpoint} = Grid;

export interface DataTableProps<T extends Model> extends Omit<TableProps<T>, 'pagination'>{
    columns: ColumnsType<T>,
    query: string,
    defaultFilters?: {[key: string]: (string | number | string[] | number [])},
    searchParamFilters?: (string | QueryFilter)[],
    pagination?: boolean
}

export const DataTable: FC<DataTableProps<any>> = ({pagination: paginationEnabled = true, columns, query, defaultFilters, searchParamFilters = [], scroll, ...rest}): ReactElement => {
    const filterPrefix = useFilterPrefix();
    const [searchParams, setSearchParams] = useSearchParams();
    const screens = useBreakpoint();
    const {page, setPage, pageFilters} = usePagination(paginationEnabled ? PAGE_SIZE : 0);

    const {data, isLoading} = useQuerySearchParams<PagedResponse | Model[]>(query, [...searchParamFilters, filterPrefix], {
        defaultFilters: {...defaultFilters, ...pageFilters}
    });
    return (
        <Table
            {...rest}
            pagination={paginationEnabled ? {
                current: page,
                pageSize: PAGE_SIZE,
                total: (data as PagedResponse)?.count
            } : false}
            dataSource={(paginationEnabled ? (data as PagedResponse)?.results : data as Model[]) || []}
            loading={isLoading}
            columns={columns}
            scroll={{x: screens.xs ? 'max-content' : undefined, ...scroll}}
            rowKey='id'
            // @ts-ignore
            onChange={(pagination, filters, sorter: SorterResult<Model>, {action}) => {
                if (action === 'paginate' && paginationEnabled) {
                    setPage(pagination.current);
                }

                if (action === 'sort' && sorter.field) {
                    // Handle nested dataIndex
                    let field = Array.isArray(sorter.field) ? sorter.field.at(-1) : sorter.field;
                    const searchParamName = `${filterPrefix}.ordering`;

                    if(sorter.order) {
                        const order = sorter.order === 'ascend' ? '' : '-';
                        searchParams.set(searchParamName, order + field);
                    }
                    else {
                        searchParams.delete(searchParamName)
                    }
                    setSearchParams(searchParams);
                }
            }}
        />
    );
};