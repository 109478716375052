import React, {FC, ReactElement, useMemo} from 'react';
import {QueryFilter} from "../../../DataTable/Filters/types";
import {DialogueChoiceAnswer, DialogueChoiceAnswerTag} from "../../../../types";
import {useParams} from "react-router-dom";
import {useLabels} from "../../../../hooks/Labels";
import {useQuerySearchParams} from "../../../../config/queryClient";
import {PieChartCard} from "../../../PieChartCard";

const searchParamFilters = [
    QueryFilter.NON_PRIMARY_ATTRIBUTES,
    QueryFilter.PRIMARY_ATTRIBUTES,
    QueryFilter.COURSES,
    QueryFilter.COHORTS
];

const dialogChoiceAnswerLabelMap: { [key in DialogueChoiceAnswerTag]: string } = {
    [DialogueChoiceAnswerTag.NEUTRAL]: 'Neutral',
    [DialogueChoiceAnswerTag.OPTIMAL]: 'Optimal',
    [DialogueChoiceAnswerTag.SUB_OPTIMAL]: 'Suboptimal',
    [DialogueChoiceAnswerTag.NEAR_OPTIMAL]: 'Near Optimal',
    [DialogueChoiceAnswerTag.INCORRECT]: 'Incorrect',
}

const answerColorMap: { [key: string]: string } = {
    'Neutral': '#1890ff',
    'Optimal': '#21D59B',
    'Suboptimal': '#faad14',
    'Near Optimal': '#95de64',
    'Incorrect': '#F0142F',
}

export const AnswerOptimalityPieChartCard: FC = (): ReactElement => {
    const {activityId, attemptId} = useParams();
    const {t} = useLabels();
    const {data, isLoading} = useQuerySearchParams<DialogueChoiceAnswer[]>(
        `/reports/dialogue-choices/answer-optimality/${attemptId ? attemptId + '/' : ''}`,
        searchParamFilters, {
            defaultFilters: {
                activity: activityId || ''
            }
        }
    )

    const chartData = useMemo(() =>
        data?.map(item => ({...item, label: dialogChoiceAnswerLabelMap[item.tag]})) || [], [data])

    return (
        <PieChartCard
            title={t('answer_optimality_title')}
            angleField='num_occurrences'
            colorField='label'
            color={({label}) => answerColorMap[label]}
            isLoading={isLoading}
            data={chartData}
        />
    );
};