import React, { FC, ReactElement } from 'react';
import {Spin} from "antd";

interface Props {
    screenSize?: boolean
}

export const SpinContainer: FC<Props> = ({screenSize}): ReactElement => {
    return (
        <div className={`spin-container ${screenSize ? 'spin-container-screen-size' : 'spin-container-full'}`}>
            <Spin size='large'/>
        </div>
    );
};